<template>
    <div id="treeView">
        <div class="tree">
            
            <ul class="dataModelTreeList" :style="padding === '0' ? 'padding-inline-start: 0px' : ''" >
                 <li class="listItems addListItem addDataButton" v-if="showAddButtonsAndSearchBar">
                     <mds-search-field
                    class="search-input-component"
                    variation="secondary"
                    v-model="searchText"
                    @input="
                      onSearchItemInList($event)
                    "
                  >
                  </mds-search-field>
                 </li>
                <li class="listItems addListItem addDataButton" v-if="showAddButtonsAndSearchBar">
                    
                     <mds-button style="margin: 0px;padding: 0;" variation="flat" icon="plus" :disabled="adminUser === false" @click="addButtonClick(addButtonName,items)" type="button">{{addButtonName}} </mds-button>
                    </li>
                    <template  v-for="(item,index) in treeViewData">
                        <li @mouseover="hovered=item.id" @mouseleave="hovered = null"
                            :class="[item.entitytypeid != 1? 'listItems': '',item.itemLevel != 1 ? 'listItemFlex': '']" :key="index" >
                            <span class="icon" @click.stop="onclick(item)" v-if="!item.showChildren && item.itemLevel == 1"><mds-icon name="triangle-fill-right" ></mds-icon></span>
                            <span class="icon" @click.stop="onclick(item)" v-if="item.showChildren && item.itemLevel == 1"><mds-icon name="triangle-fill-down" ></mds-icon></span>
                            
                            <span
                                @click="onClickOfItem(item)"
                                :style="item.itemLevel != 1 ? 'cursor: pointer;' : ''"
                                class="tree_view_data_point_name">
                                <span>{{item.name}}</span>
                            </span>
                            <mds-button
                                variation="flat"
                                class="tree_view_data_point_remove_button"
                                v-if="item.itemLevel != 1 && hovered === item.id && adminUser"
                                icon="trash" type="button"
                                @click="deleteDataPoint(item)"
                                :key="deleteDataPointKey" />              
                            
                            <span v-if="item.children.length > 0 && item.showChildren">
                                <tree-view 
                                :items="item.children" 
                                padding="20" 
                                :key="item.name+index" 
                                :showAddButtonsAndSearchBar="checkshowAddButtonsAndSearchBar(item)" 
                                :addButtonName="getAddButtonName(item)" 
                                :isDatapointSearched="isDatapointSearched"
                                @onTreeEntityTypeClick="onTreeEntityTypeClick"
                                @onDataPointClick="onDataPointClick"
                                @deleteDataPoint="deleteDataPoint"
                                @addDataPointButtonClick="addDataPointButtonClick"
                                @addDataPointGroupButtonClick="addDataPointGroupButtonClick"
                                :adminUser="adminUser"
                                ></tree-view>
                            </span>
                         </li>
                         <li :key="index+'button'" class="listItems addListItem addDataButton dataModelTreeList" v-if="item.children.length == 0  && item.showChildren && item.name == 'Data points'" style="padding-inline-start: 23px;">
                            <mds-button variation="flat" icon="plus" :disabled="adminUser === false" @click="addDatapoints(item)" type="button">{{getAddButtonName(item)}} </mds-button>
                        </li>
                         <li :key="index+'button'" class="listItems addListItem addDataButton dataModelTreeList" v-if="item.children.length == 0  && item.showChildren && item.name == 'Datapoint group'" style="padding-inline-start: 23px;">
                            <mds-button variation="flat" style="margin: 0px;padding: 0;" icon="plus" @click="addDatapointsGroup(item)" type="button">{{getAddButtonName(item)}} </mds-button>
                        </li>

                    </template>
            </ul>
        </div>
    </div>
</template>

<script>

import MdsIcon from "@mds/icon";
import MdsSearchField from "@mds/search-field";
import { MdsButton } from '@mds/button'
export default {
    name: "treeView",
    components: {
    MdsButton,
    MdsSearchField,
    MdsIcon,
  },
  props:["items","padding","showAddButtonsAndSearchBar","addButtonName","adminUser","isDatapointSearched"],
    data(){
        return {
            hovered: null,
            treeViewData:[],
            treeViewDuplicateData:[],
            searchText:'',
            deleteDataPointKey:0
        }
    },
    watch:{
        items(value){
            this.treeViewData=value
            this.treeViewDuplicateData= JSON.parse(
                JSON.stringify(this.treeViewData)
            );
        }
    },
    mounted(){
        this.treeViewData=this.items
        this.treeViewDuplicateData= JSON.parse(
                JSON.stringify(this.treeViewData)
            );
    },
    methods:{
        async onclick(item){
            if(item.showChildren){
                if(item.entitytypeid != null){
                    await this.closeAllTrees(item)
                }
                item.showChildren=false
            }else{
                item.showChildren=true
                if(item.entitytypeid == null){
                    if(item.children.length == 0){
                        if(!this.isDatapointSearched){
                             this.$emit('onTreeEntityTypeClick',item)
                        }
                    }
                }
            }
        },
        async closeAllTrees(item){
            for(let i=0;i<item.children.length;i++){
                item.children[i].showChildren=false
                if(item.children[i].entitytypeid != null && item.children[i].children.length > 0){
                    await this.closeAllTrees(item.children[i])
                }
            }
        },
        onTreeEntityTypeClick(item){
            this.$emit('onTreeEntityTypeClick',item)
        },
        checkshowAddButtonsAndSearchBar(item){
            if(item.name == "Data points" || item.name == "Datapoint group"){
                return true
            }else{
                return false
            }
        },
        deleteDataPoint(item){
            this.deleteDataPointKey+=1;
            this.$emit("deleteDataPoint",item);
        },
        getAddButtonName(item){
            if(item.name == "Data points"){
                return "Add Datapoints"
            }else if(item.name == "Datapoint group"){
                return "Add Datapoint Group"
            }else{
                return ""
            }
        },
        onClickOfItem(item){
            this.$emit('onDataPointClick',item)
        },
        onDataPointClick(item){
            this.$emit('onDataPointClick',item)
        },
        addDataPointButtonClick(data){
             this.$emit('addDataPointButtonClick',data)
        },
        addDataPointGroupButtonClick(data){
             this.$emit('addDataPointGroupButtonClick',data)
        },
        addDatapoints(item){
           let addDataPointEntityTypeDetails={
                entityLevel:item.entityLevel,
                entityLevelTypeId:item.entityLevelTypeId
            }
           this.$emit('addDataPointButtonClick',addDataPointEntityTypeDetails)
        },
        addDatapointsGroup(item){
            let addDataPointGroupEntityTypeDetails={
                entityLevel:item.entityLevel,
                entityLevelTypeId:item.entityLevelTypeId
            }
            this.$emit('addDataPointGroupButtonClick',addDataPointGroupEntityTypeDetails)
        },
        addButtonClick(buttonName,item){
            let addDataPointEntityTypeDetails={
                entityLevel:item[0].entityLevel,
                entityLevelTypeId:item[0].entityLevelTypeId
            }
            if(buttonName == 'Add Datapoints'){
                this.addDatapoints(addDataPointEntityTypeDetails)
            }else{
                this.addDatapointsGroup(addDataPointEntityTypeDetails)
            }
        },
        onSearchItemInList(event){
            this.treeViewData = this.treeViewDuplicateData.filter((item) =>item.name.toLowerCase().includes(event.toLowerCase()));
        }
    },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.entityNameHeader{
    font-size:16px;
    font-weight:600;
}

.dataModelTreeList{
    list-style-type: none;
    padding-inline-start: 23px;
}
.dataModelTreeList .mds-icon___VueMDS3Demo {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    border: 1px solid #5E5E5E;
    box-sizing: border-box;
    border-radius: 2px;
}

.tree .listItems {
    list-style-type:none;
    margin:0;
    padding:10px 5px 0 0px;
    position:relative
}
.tree .listItems::before, .tree .listItems::after {
    content:'';
    left:-15px;
    position:absolute;
    right:auto
}
.tree .listItems::before {
    border-left:1px dashed #E5E5E5;
    bottom:50px;
    height:100%;
    top:0;
    width:1px
}
.tree .listItems::after {
    border-top:1px dashed #E5E5E5;
    height:100%;
    top:20px;
    width:15px
}
.tree ul > .listItems:last-child::before {
    height: 20px;
}
.tree .addListItem::after{
    width: 0px;
}
.icon{
    cursor: pointer;
}

.tree_view_data_point_remove_button {
    float: right;
}

.tree .listItemFlex{
    display: flex;
}

.tree_view_data_point_name{
    width:90%
}

#treeView::v-deep .addDataButton .mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo, a.mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo{
    color: #006FBA !important;
}
#treeView::v-deep .addDataButton .mds-button___VueMDS3Demo.mds-button--flat___VueMDS3Demo
  .mds-button__icon___VueMDS3Demo {
  @include mds-icon-color(#006FBA);
  }
</style>