<template>
    <div id="dataModel">
        <div class="dataModelMainSection">
            <div class="dataModelHeaderSection">
                <div>
                    <h2 class="dataModel-header">Data Model</h2>
                </div>
            </div>
            <div class="dataModelSection">
                <div class="dataModelLeftSection">
                    <div>
                        <h3 class="treeViewSubHeader">Entities</h3>
                    </div>
                    <div>
                        <mds-search-field
                            class="search-input-component"
                            variation="secondary"
                            @keyup.enter="searchTextEntered()"
                            placeholder="Search Datapoints"
                            @mds-search-field-input-cleared="searchFieldCleared($event)"
                            v-model="searchField"
                        >
                        </mds-search-field>
                    </div>
                    <div class="treeViewSection">
                        <tree-view
                            :items="items"
                            padding="0"
                            :showAddButtonsAndSearchBar="false"
                            addButtonName=""
                            :adminUser="adminUser"
                            :isDatapointSearched="isDatapointSearched"
                            @onTreeEntityTypeClick="onTreeEntityTypeClick"
                            @onDataPointClick="onDataPointClick"
                            @addDataPointButtonClick="addDataPointButtonClick"
                            @addDataPointGroupButtonClick="addDataPointGroupButtonClick"
                            @deleteDataPoint="deleteDataPoint"
                        ></tree-view>
                    </div>
                </div>

                <div class="dataModelMiddelSection"></div>

                <div class="dataModelRightSection" v-if="showRightSection">
                    <div class="upperSection">
                        <div class="tabularSection">
                            <mds-tabs
                                :content="tabsContent"
                                v-on:mds-tabs-item-active="setActiveItem"
                            ></mds-tabs>
                            <div>
                                <div v-if="selectTab == 'attributes'">
                                    <attributes
                                        :selectedDataPoint="selectedDataPoint"
                                        :selectedDataPointGroup="
                                            selectedDataPointGroup
                                        "
                                        :dataTypeList="dataTypeList"
                                        :languagelist="languagelist"
                                        :key="attributeKey"
                                        :addDataPointButtonData="addDataPointButtonData"
                                        :definitions="definitions"
                                        :adminUser="adminUser"
                                        :entityLevelName="entityLevelName"
                                        :saveDataPoint="saveDataPoint"
                                        @onClickDataPointName="onClickDataPointName"
                                        @onDataPointType="onDataPointType"
                                        @showNACheckbox="showNACheckbox"
                                        @changeSubType="changeSubType"
                                        @onDataPointValueEnum="onDataPointValueEnum"
                                        @onDataPointValueRef="onDataPointValueRef"
                                        @multiLanguageClickEvent="multiLanguageClickEvent"
                                        @downArrow="downArrow"
                                        @upArrow="upArrow"
                                        @deleteDefinition="deleteDefinition"
                                        @changeTextArea="changeTextArea"
                                        :dataTypeRefEnum="dataTypeRefEnum"
                                        :dataTypeValueDropDown="dataTypeValueDropDown"
                                        :dataTypeValueTextarea="dataTypeValueTextarea"
                                    ></attributes>
                                </div>
                                <div v-if="selectTab == 'grouping'">
                                    <grouping 
                                    :groupListAvailable="groupListAvailable"
                                    :datapointListInGroup="datapointListInGroup"
                                    :selectedDataPointGroupId="dataPointGroupId"
                                    :selectedDataPoint="dataPointSelected"
                                    :adminUser="adminUser"
                                    :isAddDatapointClicked="isAddDatapointClicked"
                                    :addDatapointDetails="addDatapointDetails"
                                    @groupChanged="groupDetailsChanged"
                                    @groupDataPointsSorted="groupDataPointsSorted"
                                    @groupTypeRadioButtonChange="groupTypeRadioButtonChange"
                                    ></grouping>
                                </div>
                                <div v-if="selectTab == 'validations'">
                                    <validations 
                                    :getValidationsValue="getValidationsValue" 
                                    :adminUser="adminUser" 
                                    :selectedDataPointDetailsProp="dataPointSelected"
                                    :isAddDatapointClicked="isAddDatapointClicked"
                                    :addDatapointDetails="addDatapointDetails"
                                    ></validations>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="footerSection"  v-if="adminUser === true">
                            <mds-layout-grid>
                                <mds-row>
                                    <mds-col>
                                        <div class="borderLine"></div>
                                    </mds-col>
                                </mds-row>
                                <mds-row>
                                    <!-- <mds-col>
                                        <span>Delete Data Point</span>
                                    </mds-col> -->
                                    <mds-col class="saveButton">
                                        <span>
                                            <mds-button
                                                v-on:click="addButton"
                                                variation="primary"
                                                :disabled="addButtonDisable"
                                                >{{buttonName}}
                                            </mds-button>
                                        </span>
                                    </mds-col>
                                </mds-row>
                            </mds-layout-grid>
                        </div>
                    </div>
            </div>
        </div>
        <loader-component v-if="displayLoader"></loader-component>
    </div>
</template>

<script>
import MdsTabs from "@mds/tabs";
import TreeView from "./TreeView.vue";
import Attributes from "./Attributes.vue";
import Grouping from "./Grouping.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import { MdsButton } from "@mds/button";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import Validations from './Validations.vue';
import MdsSearchField from "@mds/search-field";

export default {
    name: "data-model",

    components: {
        MdsTabs,
        TreeView,
        Attributes,
        Grouping,
        LoaderComponent,
        MdsButton,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        Validations,
        MdsSearchField
    },

    data() {
        return {
            dataPointGroupId:null,
            items: [],
            selectedDataPoint: {},
            selectedDataPointGroup: {},
            attributeKey:0,
            datapointname:"",
            datapointtype:"",
            datapointdefinition:"",
            addDataPointButtonData:{},
            dataTypeRefEnum: false,
            searchField:''
        };
    },

    computed:{
        // addButtonDisable(){
        //     if(Object.keys(this.selectedDataPoint).length > 1){
        //         return this.selectedDataPoint && this.datapointdefinition
        //     }else {
        //         return this.datapointname && this.datapointtype && this.datapointdefinition
        //     }
        // }
    },

    props: {
        tabsContent: {
            type: Array,
            default: () => [],
        },
        selectTab: {
            type: String,
            default: () => "",
        },
        treeViewList: {
            type: Array,
            default: () => [],
        },
        displayLoader: {
            type: Boolean,
            default: false,
        },
        isAddDatapointClicked: {
            type: Boolean,
            default: false,
        },
        dataPointSelected: {
            default: () => {},
        },
        addDatapointDetails:{
            default: () => {},
        },
        datapointGroupSelected: {
            default: () => {},
        },
        languagelist: {
            type: Array,
            default: () => [],
        },
        dataTypeList: {
            type: Array,
            default: () => [],
        },
        groupListAvailable: {
            type: Array,
            default: () => [],
        },
        datapointListInGroup: {
            type: Array,
            default: () => [],
        },
        selectedDataPointGroupId:{
            type:Number
        },
        dataTypeValueDropDown: {
            type: Array
        },
        dataTypeValueTextarea: {
            type: String
        },
        getValidationsValue: {
            type: Array
        },
        definitions: {
            type: Array
        },
        adminUser: {
            type: Boolean
        },
        entityLevelName: {
            type: String
        },
        addButtonDisable: {
            type: Boolean
        },
        saveDataPoint: {
            type: Object
        },
        showRightSection:{
            type: Boolean
        },
        isDatapointSearched:{
            type: Boolean
        },
        buttonName: {
            type: String
        }
    },
    watch: {
        treeViewList(value) {
            this.items = value;
        },
        
        dataPointSelected(value) {
            this.selectedDataPoint = value;
            if(value.enumid != null || value.referencedentitytypeid != null){
                this.dataTypeRefEnum = true; 
            } else{
                this.dataTypeRefEnum = false; 
            }
            this.datapointdefinition = value.description;
        },
        datapointGroupSelected(value) {
            this.selectedDataPointGroup = value;
        },
        selectedDataPointGroupId(value) {
            this.dataPointGroupId=value
        },
    },
    
    methods: {
        searchFieldCleared(){
            this.$emit("emptySearchedDataPoints")
        },
        searchTextEntered(){
            if(this.searchField.length === 0){
                this.$emit("emptySearchedDataPoints")
            }else if(this.searchField.length > 3){
                this.$emit("datapointSearched",this.searchField)
            } 
        },
        groupTypeRadioButtonChange(isRepeatingGroup){
            this.$emit("groupTypeRadioButtonChange",isRepeatingGroup)
        },
        groupDetailsChanged(groupId){
            this.$emit("groupDetailsChanged",groupId)
        },
        groupDataPointsSorted(values){
            this.$emit("groupDataPointsSorted",values)
        },
        setActiveItem(event) {
            this.$emit("setActiveItem", event);
        },
        onTreeEntityTypeClick(entityTypeItem) {
            this.$emit("onTreeEntityTypeClick", entityTypeItem);
        },
        onDataPointClick(item) {
            if(item.id !== undefined){
                this.$emit("onDataPointClick", item);
            }
        },
        async addDataPointButtonClick(data) {
            this.selectedDataPoint={}
            this.datapointname = "";
            this.datapointtype = "";
            this.datapointdefinition = "";
            this.dataTypeRefEnum = false;
            this.addDataPointButtonData = data;
            await this.$emit("addDataPointButtonClick", data);
            if(this.showRightSection){
                document.getElementById(this.tabsContent[1].id).style.pointerEvents = "none"
                document.getElementById(this.tabsContent[1].id).style.opacity = "0.4"
                document.getElementById(this.tabsContent[2].id).style.pointerEvents = "none"
                document.getElementById(this.tabsContent[2].id).style.opacity = "0.4"
            }
        },
        addDataPointGroupButtonClick(data) {
            this.$emit("addDataPointGroupButtonClick", data);
        },
        addButton(){
            this.$emit("addButton");
        },
        onClickDataPointName(event){
            this.datapointname = event;
            this.$emit("onClickDataPointName", event)
        },
        onDataPointType(selectedVal){  
            this.datapointtype = selectedVal.label;
            this.dataTypeRefEnum = selectedVal.givemultiselect
            this.$emit("onDataPointType", selectedVal)
        },
        showNACheckbox(event) {
            this.$emit("showNACheckbox", event)
        },
        changeSubType(event){
            this.$emit("changeSubType", event)
        },
        onDataPointValueEnum(event){
            this.$emit("onDataPointValueEnum", event)
        },
        onDataPointValueRef(event){
            this.$emit("onDataPointValueRef", event)
        },
        changeTextArea(event,value){
            this.datapointdefinition = event
            this.$emit("changeTextArea", event, value)
        },
        multiLanguageClickEvent(value){
            this.$emit("multiLanguageClickEvent", value)
        },
        upArrow(value){
            this.$emit("upArrow", value)
        },
        downArrow(value){
            this.$emit("downArrow", value)
        },
        deleteDefinition(value, index){
            this.$emit("deleteDefinition", value, index)
        },
        deleteDataPoint(item){
            this.$emit("deleteDataPoint",item)
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.dataModelMainSection {
    margin: 16px;
}
.dataModel-header {
    margin-top: 16px;
    padding-top: 16px;
    @include mds-level-2-heading($bold: false);
}
.dataModelSection {
    display: flex;
}
.dataModelLeftSection {
    width: 29%;
    border-top: 1px solid #808080;
}
.dataModelMiddelSection {
    width: 2%;
}
.dataModelRightSection {
    width: 69%;
}
.treeViewSection {
    margin-top: 5px;
    height: 70vh;
    overflow: auto;
}
.treeViewSubHeader {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 16px;
}
.footerSection {
    margin-top: 10px;
    margin-bottom: 10px;
}
.borderLine {
    border-top: 1px solid $mds-color-neutral-80;
    margin-bottom: 16px;
}
.saveButton {
    text-align: right;
}
.upperSection {
    min-height: 70vh;
}
</style>
