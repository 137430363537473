<template>
    <div id="definition">
        <div>
            <div>
                <mds-layout-grid>
                        <mds-row>
                            <mds-col :cols="6">
                                <span class="definitionTitle">Definition</span>
                            </mds-col>
                            <mds-col :cols="6" class="upDownArrows" v-if="adminUser === true">
                                <mds-button 
                                    icon="caret-down"
                                    variation="flat"
                                    type="button"
                                    @click="multiLanguageButton" 
                                >Definition Language
                                </mds-button>
                                <div v-if="show">
                                    <mds-search-results class="multiLanguageDropDown">
                                        <mds-list-group size="small" variation="selection" class="multiLanguageList">
                                            <mds-list-group-item v-for="(item, index) in languagelist"
                                            :key="index"
                                            >
                                            <span
                                                v-html="item.text"
                                                @click="
                                                    multiLanguageClickEvent(item)
                                                "
                                            ></span>
                                            </mds-list-group-item>
                                        </mds-list-group>
                                    </mds-search-results>
                                </div>
                            </mds-col>
                        </mds-row>
                        <div v-for="(item, index) in definitions" :key="index">
                            <div class="definitionBorderSection">
                                <div class="insideDefinitionSection">
                                    <mds-form action="javascript:void(0)">
                                        <mds-row>
                                            <mds-col :cols="6">
                                                <span class="definitionTitle">{{item.definitionname}} Definition</span>
                                                <span style="margin-left: 10px;">
                                                    <mds-button 
                                                        v-if="item.ietflanguagetag != 'en'"
                                                        icon="trash"
                                                        variation="icon-only"
                                                        type="button"
                                                        @click="deleteDefinition(item,index)"
                                                        size="small"
                                                    ></mds-button>
                                                </span>
                                            </mds-col>
                                            <mds-col :cols="6" style="text-align:right">
                                                <mds-button 
                                                    v-if="item.isExpand == true"
                                                    icon="caret-up"
                                                    variation="icon-only"
                                                    type="button"
                                                    @click="upArrow(item)"
                                                    size="small"
                                                >
                                                </mds-button>
                                                <mds-button
                                                    v-if="item.isExpand == false" 
                                                    icon="caret-down"
                                                    variation="icon-only"
                                                    type="button"
                                                    @click="downArrow(item)"
                                                    size="small"
                                                >
                                                </mds-button>
                                            </mds-col>
                                        </mds-row>
                                        <div class="definitionTextArea" v-if="item.isExpand == true">
                                            <mds-textarea hidden-label label="Definition" maxlength="500" v-if="adminUser === true" @input="changeTextArea($event,definitions)" v-model="item.description"></mds-textarea>
                                            <mds-textarea hidden-label label="Definition" maxlength="500" readonly v-if="adminUser === false" @input="changeTextArea($event,definitions)" v-model="item.description"></mds-textarea>
                                        </div>
                                    </mds-form>
                                </div>
                            </div>
                        </div>
                </mds-layout-grid>
            </div>
        </div>
    </div>
</template>

<script>
import MdsForm from "@mds/form";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsButton } from "@mds/button";
import MdsTextarea from '@mds/textarea';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import { MdsSearchResults } from '@mds/search-results'

export default {
    name: "definition",

    components: {
        MdsForm,
        MdsLayoutGrid, 
        MdsRow, 
        MdsCol,
        MdsButton,
        MdsTextarea,
        MdsListGroup, 
        MdsListGroupItem,
        MdsSearchResults, 
    },

    data(){
        return {
            definitionname:"",
            textarea:""
        }
    },

    props: {
        languagelist:{
            type: Array,
            default: () => [] 
        },
        description:{
            type: String,
            default: () => "" 
        },
        addDataPointButtonData:{
            type: Object
        },
        definitions: {
            type: Array
        },
        adminUser: {
            type: Boolean
        },
        saveDataPoint:{
            type: Object
        },
        show: {
            type: Boolean
        },      
    },

    watch: {
        addDataPointButtonData(value){
            console.log(value);
        },       
    },

    methods : {
        upArrow(value){
            this.$emit("upArrow", value)
        },
        downArrow(value){
            this.$emit("downArrow", value)
        },
        multiLanguageButton(){
            this.$emit("multiLanguageButton")
        },
        multiLanguageClickEvent(value){
            this.$emit("multiLanguageClickEvent", value)
        },
        changeTextArea(event, value){
            this.$emit("changeTextArea", event, value)
        },
        deleteDefinition(value, index){
            this.$emit("deleteDefinition", value, index)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.definitionTitle{
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
}
.multiLanguageDropDown{
    position: absolute;
    width: 180px;
    right: 15px;
    margin-top: 5px;
    background-color: #E5E5E5;
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
}
.multiLanguageList{
    text-align: left;
}
.definitionTextArea{
    margin-top: 10px;
}
.definitionBorderSection{
    margin-top:10px;
    border: 1px solid #CCCCCC;
    border-radius: 6px;
    box-sizing: border-box;
}
.insideDefinitionSection{
    margin:10px 16px;
}
.upDownArrows{
    text-align:right;
    position:relative
}

</style>