<template>
    <div id="validations">
        <div class="mainSection">
            <mds-layout-grid>
                <mds-form action="javascript:void(0)">
                    <mds-row>
                        <mds-col :cols="6" v-if="adminUser === true">
                            <mds-combo-box
                                id="validation_list"
                                label="Validations"
                                placeholder="Select Value"
                                :data-set="validationList"
                                v-model="selectComboValues"
                                @input="onValidationsChange()"
                                :key="validationComboKey"
                                multiple
                            ></mds-combo-box>
                        </mds-col>
                        <mds-col :cols="6" v-if="adminUser === false">
                            <div class="validationTitle">Validation</div>
                            <span  v-for="(fields,index) in validationFieldListDisplay" :key="index">
                                 <span>{{fields.label}}</span>
                                <span v-if="index + 1 <validationFieldListDisplay.length">, </span>
                            </span>
                        </mds-col>
                    </mds-row>
                
                </mds-form>
            </mds-layout-grid>
            <div style="margin-top:16px;" v-if="adminUser === true">
                <mds-form>
                    <div class="validation-list" v-for="(fields,index) in validationFieldListDisplay" :key="index">
                        <div class="validation-list_grpblk">
                            <div class="grouping_list_grpTitle">
                                <div class="validation-list-checkbox">
                                    <p class="validationFieldLable">{{fields.label}}</p>
                                    <div style="width:65%">
                                    <mds-input
                                        :id="fields.label+'_'+index"
                                        v-if="(fields.dataTypeId === 1 || fields.dataTypeId === 7) && fields.displayInput"
                                        hidden-label
                                        type="number"
                                        class="inputRequired"
                                        :maxlength="enums.inputMaxLength"
                                        label="Integer"
                                         max="2147483647"
                                        :value="fields.inputTypeValue"
                                        @change="(v) =>((fields.inputTypeValue = checkMax(v,fields)),(onValidationInputValueChange()))"
                                        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                                    ></mds-input>
                                    <mds-input
                                         :id="fields.label+'_'+index"
                                        v-if="fields.dataTypeId === 2 && fields.displayInput"
                                        hidden-label
                                        type="Number"
                                        :maxlength="enums.inputMaxLength"
                                        class="inputRequired"
                                        label="Number"
                                        @change="onValidationInputValueChange()"
                                         v-model="fields.inputTypeValue"
                                        onkeydown="return event.keyCode !== 69"
                                    ></mds-input>
                                    <div :id="fields.label+'_'+index" v-if="fields.dataTypeId === 3 && fields.displayInput">
                                    <mds-date-picker
                                         
                                        v-model="fields.inputTypeValue"
                                        :value="fields.inputTypeValue"
                                        
                                        label=""
                                        hidden-label
                                        :min-max-dates="{min: new Date(1800, 0, 1), max: new Date(3025, 8, 15)}"
                                        :labels="{month: 'short'}"
                                        @input="onValidationInputValueChange()"
                                        :highlightToday="true"
                                        :date-format="{locale:'en-gb'}"
                                    ></mds-date-picker>
                                    </div>
                                    <!-- <mds-input
                                    v-if="fields.dataTypeId === 3 && fields.displayInput"
                                        hidden-label
                                        type="date"
                                        label="Date"
                                        @change="onValidationInputValueChange()"
                                         v-model="fields.inputTypeValue"
                                    ></mds-input> -->
                                    <mds-input
                                     :id="fields.label+'_'+index"
                                    v-if="fields.dataTypeId === 4 && fields.displayInput"
                                        hidden-label
                                        type="time"
                                        label="Time"
                                        step="1"
                                        @change="onValidationInputValueChange()"
                                         v-model="fields.inputTypeValue"
                                    ></mds-input>
                                    <mds-input
                                     :id="fields.label+'_'+index"   
                                        v-if="fields.dataTypeId === 5 && fields.displayInput"
                                        hidden-label
                                        label="String short"
                                        :maxlength="enums.inputMaxLength"
                                         v-model="fields.inputTypeValue"
                                         @change="onValidationInputValueChange()"
                                    ></mds-input>
                                    <mds-textarea
                                     :id="fields.label+'_'+index"
                                    v-if="fields.dataTypeId === 6 && fields.displayInput"
                                        label="String long"
                                         hidden-label
                                         @change="onValidationInputValueChange()"
                                         v-model="fields.inputTypeValue"
                                    ></mds-textarea>
                                    </div>
                                    <div class="deleteButtonDiv">
                                        <mds-button
                                            icon="trash"
                                            variation="icon-only"
                                            type="button"
                                            @click="onDeleteValidationClick(fields)"
                                            >Discard Changes
                                            </mds-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mds-form>
            </div>
        </div>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
    ></notification-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsComboBox from "@mds/combo-box";
import MdsInput from "@mds/input";
import MdsTextarea from "@mds/textarea";
import Enums from "../../../../config/Enums.json";
import { MdsButton } from "@mds/button";
import MdsDatePicker from '@mds/date-picker'
import NotificationComponent from '../../../ui_component/notificationComponent.vue';
export default {
    name: "validations",

    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        MdsComboBox,
        MdsInput,
        MdsTextarea,
        MdsButton,
        MdsDatePicker,
        NotificationComponent
    },

    props: {
        getValidationsValue: {
            type: Array
        },
        adminUser: {
            type: Boolean
        },
        selectedDataPointDetailsProp:{
             default: () => {},
        },
        isAddDatapointClicked: {
            type: Boolean,
            default: false,
        },
        addDatapointDetails:{
            default: () => {},
        },
    },
    watch:{
        isAddDatapointClicked(){
            this.getAddedDatapointDetails()
        },
        selectedDataPointDetailsProp(value) {
            this.selectedDataPointDetails=value
        },
        getValidationsValue(value){
             if(Object.keys(this.selectedDataPointDetails).length > 0){
                if(this.selectedDataPointDetails.validations.length > 0){
                        this.getSelectedValidationValues()
                }else{
                        this.selectComboValues=[]
                }
             }else{
                 this.selectComboValues=[]
             }
            this.validationList=Object.assign(value)
            if(this.isAddDatapointClicked){
                this.getAddedDatapointDetails()
                if(this.addDatapointDetails.validations.length > 0){
                        this.getSelectedValidationValues()
                }else{
                        this.selectComboValues=[]
                }
            }else{
                this.displayValidationsBasedOnDataType()
            }
        }
    },
    data() {
        return {
            defaultDataTypes:Enums.dataTypes,
            selectComboValues: [],
            selectedValidationValues:[],
            validationList:[],
            validationComboKey:0,
            selectedDatapointDataTypeId:0,
            validationFieldListDisplay:[],
            selectedDataPointDetails:{},
            enums:Enums,
            notificationType : "",
            notificationMessage:"",
            notificationKey : "",
            showNotification : false
            
        };
    },
    mounted(){
        this.selectedDataPointDetails=this.selectedDataPointDetailsProp
        if(Object.keys(this.selectedDataPointDetails).length > 0){
            this.selectedDatapointDataTypeId=this.selectedDataPointDetails.datatypeid
        }else{
            this.selectComboValues=[]
        }
        this.validationList=Object.assign(this.getValidationsValue) 
        if(this.validationList.length>0){
            if(this.isAddDatapointClicked){
                this.getAddedDatapointDetails()
                if(this.addDatapointDetails.validations.length > 0){
                        this.getSelectedValidationValues()
                }else{
                        this.selectComboValues=[]
                }
            }else{
                this.displayValidationsBasedOnDataType()
            }
        }
        
    },
    methods:{
        checkMax(event,fields){
            if(parseInt(event.target.value) > 2147483647){
                this.notificationType = "warning"
                this.notificationMessage="Value must be less than or equal to 2147483647"
                this.notificationKey = "warning-default"
                this.showNotification = true
                if(fields.inputTypeValue !== null){
                return fields.inputTypeValue.length === 0 ? null : parseInt(fields.inputTypeValue)
                }else{
                    return ""
                }
            }else{
                return event.target.value
            }
        },
        getAddedDatapointDetails(){
            if(this.isAddDatapointClicked){
                let datapointDetails={
                    arraylength: this.addDatapointDetails.arrayLength,
                    automappings: this.addDatapointDetails.autoMappings,
                    datapointdependencytypeid: this.addDatapointDetails.dataPointDependencyTypeId,
                    datapointdescription: this.addDatapointDetails.dataPointDescription,
                    datapointexportname: this.addDatapointDetails.dataPointExportName,
                    datapointgroupid: this.addDatapointDetails.dataPointGroupId,
                    datapointid: this.addDatapointDetails.dataPointId,
                    datapointname: this.addDatapointDetails.dataPointName,
                    datatypeid: this.addDatapointDetails.dataTypeId,
                    datatypelabel:this.addDatapointDetails.dataTypeLabel,
                    definitions: this.addDatapointDetails.definitions,
                    disabledasdefault: this.addDatapointDetails.disabledAsDefault,
                    entitytypeid: this.addDatapointDetails.entityTypeId,
                    enumid: this.addDatapointDetails.enumId,
                    referencedentitytypeid: this.addDatapointDetails.referencedEntityTypeId,
                    showinfilter: this.addDatapointDetails.showInFilter,
                    shownacheckbox: this.addDatapointDetails.showNACheckbox,
                    sortorders: this.addDatapointDetails.sortOrders,
                    validations: this.addDatapointDetails.validations,
                }
                this.selectedDataPointDetails=datapointDetails
                this.selectedDatapointDataTypeId=this.selectedDataPointDetails.datatypeid
                 this.displayValidationsBasedOnDataType()
            }
        },
        getSelectedValidationValues(){
            this.selectComboValues=[]
            
             if(this.isAddDatapointClicked){
                  for(let i=0;i<this.addDatapointDetails.validations.length;i++){
                    this.selectComboValues.push(this.addDatapointDetails.validations[i].datapointvalidationoperationid)
                }
                if(this.selectComboValues.length > 0){
                    this.onValidationsChange()
                }
             }else{
                 if(Object.keys(this.selectedDataPointDetails).length > 0){
                    for(let i=0;i<this.selectedDataPointDetails.validations.length;i++){
                        this.selectComboValues.push(this.selectedDataPointDetails.validations[i].datapointvalidationoperationid)
                    }
                    if(this.selectComboValues.length > 0){
                        this.onValidationsChange()
                    }
                }
             }
        },
        onValidationsChange(){
            let dataTypeText=''
            this.selectedValidationValues=[]
            for(let key in this.defaultDataTypes){
                if(this.defaultDataTypes[key] == this.selectedDatapointDataTypeId){
                    dataTypeText = key
                    break;
                }
            }

            for(let i=0;i<this.selectComboValues.length;i++){
                let validationFieldsJson={
                    datapointvalidationoperationid:this.selectComboValues[i],
                    dataTypeId:this.selectedDatapointDataTypeId,
                    label:'',
                    inputTypeToDisplay:dataTypeText,
                    inputTypeValue:'',
                    displayInput:true,
                    numbervalue:null,
                    datevalue:null,
                    timevalue:null,
                    stringvalue:null

                }
                for(let j=0;j<this.validationList.length;j++){
                    if(this.selectComboValues[i] === this.validationList[j].value){
                        validationFieldsJson.label=this.validationList[j].text
                    }
                }
                for(let n=0;n<this.validationList.length;n++){
                    if(this.selectComboValues[i] === this.validationList[n].value){
                        if((this.validationList[n].label === "EqualStringLength" || this.validationList[n].label === "StringLengthShorterThan") && (this.selectedDatapointDataTypeId === this.defaultDataTypes.StringShort || this.selectedDatapointDataTypeId === this.defaultDataTypes.StringLong)){
                            validationFieldsJson.dataTypeId=this.defaultDataTypes.Integer
                            validationFieldsJson.inputTypeToDisplay="Integer"
                        }
                        if(this.validationList[n].label === "Mandatory" || this.validationList[n].label === "DistinctArrayValues" || this.validationList[n].label === "NotFutureDate"||this.validationList[n].label === "BusinessDay"||this.validationList[n].label === "MandatoryEffectiveDate" ||this.validationList[n].label === "DuplicationCheck"){
                            validationFieldsJson.displayInput=false
                        }

                    }
                }
                if(this.isAddDatapointClicked){
                  for(let k=0;k<this.addDatapointDetails.validations.length;k++){
                      if(this.selectComboValues[i] === this.addDatapointDetails.validations[k].datapointvalidationoperationid){
                            if(validationFieldsJson.dataTypeId === this.defaultDataTypes.Integer || validationFieldsJson.dataTypeId === this.defaultDataTypes.Number){
                                validationFieldsJson.inputTypeValue=this.addDatapointDetails.validations[k].numbervalue
                            }else if(validationFieldsJson.dataTypeId === this.defaultDataTypes.Date){
                                validationFieldsJson.inputTypeValue= this.addDatapointDetails.validations[k].datevalue ? new Date(this.addDatapointDetails.validations[k].datevalue) : this.addDatapointDetails.validations[k].datevalue;
                            }else if(validationFieldsJson.dataTypeId === this.defaultDataTypes.Time){
                                validationFieldsJson.inputTypeValue=this.addDatapointDetails.validations[k].timevalue
                            }else if(validationFieldsJson.dataTypeId === this.defaultDataTypes.StringShort || validationFieldsJson.dataTypeId === this.defaultDataTypes.StringLong){
                                validationFieldsJson.inputTypeValue=this.addDatapointDetails.validations[k].stringvalue
                            }
                            
                        }
                  }
                  this.selectedValidationValues.push(validationFieldsJson)
                }else{
                    if(this.selectedDataPointDetails.validations.length > 0){
                        for(let k=0;k<this.selectedDataPointDetails.validations.length;k++){
                            if(this.selectComboValues[i] === this.selectedDataPointDetails.validations[k].datapointvalidationoperationid){
                                if(validationFieldsJson.dataTypeId === this.defaultDataTypes.Integer || validationFieldsJson.dataTypeId === this.defaultDataTypes.Number){
                                    validationFieldsJson.inputTypeValue=this.selectedDataPointDetails.validations[k].numbervalue
                                }else if(validationFieldsJson.dataTypeId === this.defaultDataTypes.Date){
                                    validationFieldsJson.inputTypeValue= this.selectedDataPointDetails.validations[k].datevalue ? new Date(this.selectedDataPointDetails.validations[k].datevalue) : this.selectedDataPointDetails.validations[k].datevalue;
                                }else if(validationFieldsJson.dataTypeId === this.defaultDataTypes.Time){
                                    validationFieldsJson.inputTypeValue=this.selectedDataPointDetails.validations[k].timevalue
                                }else if(validationFieldsJson.dataTypeId === this.defaultDataTypes.StringShort || validationFieldsJson.dataTypeId === this.defaultDataTypes.StringLong){
                                    validationFieldsJson.inputTypeValue=this.selectedDataPointDetails.validations[k].stringvalue
                                }
                                
                            }
                        }
                    }
                    this.selectedValidationValues.push(validationFieldsJson)
                }
                
            }
            this.validationFieldListDisplay=this.selectedValidationValues
            for(let j=0;j<this.validationFieldListDisplay.length;j++){
                if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Integer || this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Number){
                    this.validationFieldListDisplay[j].numbervalue=this.validationFieldListDisplay[j].inputTypeValue
                }else if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Date){
                    this.validationFieldListDisplay[j].datevalue=this.validationFieldListDisplay[j].inputTypeValue
                }else if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Time){
                    this.validationFieldListDisplay[j].timevalue=this.validationFieldListDisplay[j].inputTypeValue
                }else if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.StringShort || this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.StringLong){
                    this.validationFieldListDisplay[j].stringvalue=this.validationFieldListDisplay[j].inputTypeValue
                }
                        
            }
            if(this.validationFieldListDisplay.length > 0){
                if(this.isAddDatapointClicked){
                this.addDatapointDetails.validations=this.validationFieldListDisplay
                }else{  
                    this.selectedDataPointDetails.validations=this.validationFieldListDisplay
                    }
            }
        },
        onValidationInputValueChange(){
             for(let j=0;j<this.validationFieldListDisplay.length;j++){
                if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Integer || this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Number){
                    this.validationFieldListDisplay[j].numbervalue=this.validationFieldListDisplay[j].inputTypeValue
                }else if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Date){
                    this.validationFieldListDisplay[j].datevalue=this.validationFieldListDisplay[j].inputTypeValue
                }else if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.Time){
                    this.validationFieldListDisplay[j].timevalue=this.validationFieldListDisplay[j].inputTypeValue
                }else if(this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.StringShort || this.validationFieldListDisplay[j].dataTypeId === this.defaultDataTypes.StringLong){
                    this.validationFieldListDisplay[j].stringvalue=this.validationFieldListDisplay[j].inputTypeValue
                }
                        
            }
            
            if(this.validationFieldListDisplay.length > 0){
                if(this.isAddDatapointClicked){
                    this.addDatapointDetails.validations=this.validationFieldListDisplay
                }else{
                    this.selectedDataPointDetails.validations=this.validationFieldListDisplay
                }
            }
        },
        onDeleteValidationClick(field){
            let i = this.selectComboValues.length
            while(i--){
                if(this.selectComboValues[i] == field.datapointvalidationoperationid){
                    this.selectComboValues.splice(i,1)
                }
            }
            this.validationComboKey++
            this.onValidationsChange()
        },
        displayValidationsBasedOnDataType(){
            if(this.validationList.length > 0){
                let i=this.validationList.length
                if(this.selectedDataPointDetails.datatypeid === 1 || this.selectedDataPointDetails.datatypeid === 2 || this.selectedDataPointDetails.datatypeid === 4){
                    while(i--){
                        if(this.validationList[i].label === "EqualStringLength"  || this.validationList[i].label === "BusinessDay" || this.validationList[i].label === "StringLengthShorterThan"){
                            this.validationList.splice(i,1)
                        }
                    }
                }else if(this.selectedDataPointDetails.datatypeid === 3){
                     while(i--){
                        if(this.validationList[i].label === "EqualStringLength" || this.validationList[i].label ==="StringLengthShorterThan"){
                            this.validationList.splice(i,1)
                        }
                    }
                }else if((this.selectedDataPointDetails.datatypeid === 5 && this.selectedDataPointDetails.datatypelabel !== "Enum") || this.selectedDataPointDetails.datatypeid === 6){
                    while(i--){
                        if(this.validationList[i].label === "GreaterThan" || this.validationList[i].label === "GreaterThanOrEqualTo" || this.validationList[i].label === "SmallerThan" || this.validationList[i].label === "SmallerThanOrEqualTo"  || this.validationList[i].label === "BusinessDay"){
                            this.validationList.splice(i,1)
                        }
                    }
                }else if(this.selectedDataPointDetails.datatypeid === 7 || (this.selectedDataPointDetails.datatypeid === 5 && this.selectedDataPointDetails.datatypelabel === "Enum")){
                    while(i--){
                        if(this.validationList[i].label === "GreaterThan" || this.validationList[i].label === "GreaterThanOrEqualTo" || this.validationList[i].label === "SmallerThan" || this.validationList[i].label === "SmallerThanOrEqualTo" || this.validationList[i].label === "EqualStringLength" || this.validationList[i].label === "BusinessDay" || this.validationList[i].label === "StringLengthShorterThan"){
                            this.validationList.splice(i,1)
                        }
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.mainSection {
    margin-top: 16px;
}
.validation-list,
.validation-list_group .grouping_list_grpTitle {
    display:block;
    border:1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 8px;
    width: 80%;
    
}
.validation-list_grpblk {
    width: 100%;
}
.validation-list.activeCol .validation-list_group {
    display: block;
}
.validation-list_group {
    display: none;
}
.validation_list_grpTitle,
.validation-list-checkbox {
    display: flex;
}
.validation-list_group .validation_list_grpTitle {
    margin-left: 28px;
    width: 96%;
}
.validationFieldLable{
    margin: 0;
    margin-right: 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    width: 25%;
}
.validation-list-checkbox::v-deep .mds-input___VueMDS3Demo{
 width: 50%;
}
.validation-list-checkbox::v-deep .mds-date-picker___VueMDS3Demo{
     width: 50%;
}
.deleteButtonDiv{
    width: 10%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.validationTitle {
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
    margin-bottom: 16px;
}
</style>