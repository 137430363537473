<template>
    <div id="grouping">
        <div class="groupingMainSection">
         <mds-layout-grid class="create_alert">
            <!-- <mds-row style="margin:0;margin-top:16px;">
                <mds-form>
                    <mds-fieldset variation="radio-group">
                        <mds-radio-button
                            
                            class="radioButtonGroup"
                            name="group"
                            v-for="buttonTypes in getButtonTypes"
                            :key="buttonTypes.id"
                            v-model="switchRadioButtonType"
                            :value="buttonTypes.id"
                            :label="buttonTypes.lable"
                            @input="onRadioButtonChange()"
                            :disabled="disableRadioButton(buttonTypes)"
                            :id="'group_radio_buttons_'+buttonTypes.lable"
                        ></mds-radio-button>
                    </mds-fieldset>
                </mds-form>
             </mds-row> -->
            <mds-row style="margin-top:16px;" v-if="switchRadioButtonType != 0">
                <mds-col :cols="6"  v-if="adminUser === true">
                    <mds-form>
                        <mds-select
                            :key="displayGroupKey"
                            label="Display Groups"
                            v-model="selectedGroupId"
                            :disabled="disableGroupsDropDown"
                            @change="onGroupChange()"
                            placeholder="Select..."
                            id="available_group_drop_down"
                        >
                        <option
                            v-for="group in availableGroups"
                            :key="group.datapointgroupdid"
                            :value="group.datapointgroupdid"
                        >
                            {{ group.datapointgroupdescription }}
                        </option>
                        </mds-select>
                     </mds-form>
                </mds-col>
                <mds-col  v-if="adminUser === false">
                    <div class="groupingTitle">Display Groups</div>
                    <span  v-for="(fields,index) in availableGroups" :key="index">
                            <span v-if="selectedGroupId === fields.datapointgroupdid">{{fields.datapointgroupdescription}}</span>
                    </span>
                </mds-col>
                <mds-col>
                    <div v-if="groupDataPointList.length > 0">
                         <mds-form>
                                    <mds-input class="readOnlyInput" readonly  label="Group Order"></mds-input>
                                </mds-form>
                        <draggable v-model="groupDataPointList" @change="sortableData">
                            <transition-group>
                                <div class="grouping-list" v-for="(item, indexList) in groupDataPointList" :key="indexList">
                                    <div class="grouping-list_grpblk">
                                        <div class="grouping_list_grpTitle">
                                            <div class="grouping-list-dragdrop-button">
                                                <mds-button
                                                    variation="icon-only"
                                                    icon="grab-handle"
                                                    type="button">
                                                </mds-button>
                                            </div>
                                            <div class="grouping-list-checkbox">
                                                {{item.datapointname}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </draggable>
                    </div>
                </mds-col>
                
            </mds-row>
         </mds-layout-grid>
        </div>
    </div>
</template>

<script>
// import MdsRadioButton from "@mds/radio-button";
// import MdsFieldset from "@mds/fieldset";
import { MdsLayoutGrid, MdsRow,MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsSelect from '@mds/select'
import draggable from 'vuedraggable';
import { MdsButton } from "@mds/button";
import MdsInput from '@mds/input'
export default {
    name: "grouping",
    components:{
        // MdsRadioButton,
        // MdsFieldset,
        MdsLayoutGrid, 
        MdsRow,
        MdsCol,
        MdsForm,
        MdsSelect,
        draggable,
        MdsButton,
        MdsInput
    },
    props: {
        groupListAvailable: {
            type: Array,
            default: () => [],
        },
        datapointListInGroup: {
            type: Array,
            default: () => [],
        },
        selectedDataPointGroupId:{
            type: Number
        },
        selectedDataPoint:{
            default: () => {},
        },
        adminUser: {
            type: Boolean
        },
        isAddDatapointClicked: {
            type: Boolean,
            default: false,
        },
        addDatapointDetails:{
            default: () => {},
        },

    },
    watch: {
        isAddDatapointClicked(){
            this.getAddedDatapointDetails()
        },
        selectedDataPoint(value) {
            this.dataPointSelected = value;
            this.checkRadioButtonOnDefault()
        },
        groupListAvailable(value) {
            this.availableGroups = value;
            this.displayGroupKey++
        },
        datapointListInGroup(value) {
            this.groupDataPointList = value;
            
        },
        groupDataPointList(value){
           if(this.switchRadioButtonType === 2 && this.isAddDatapointClicked){
                this.addedDataPointObject.sortOrders=value
            }
        },
        selectedDataPointGroupId(value) {
            this.selectedGroupId = value;
            this.displayGroupKey++
        },
    },
    data(){
        return {
            addedDataPointObject:{},
            dataPointSelected:{},
            switchRadioButtonType:0,
            availableGroups:[],
            selectedGroupId:null,
            displayGroupKey:0,
            disableGroupsDropDown:false,
            getButtonTypes:[
                {
                    id: 1,
                    lable: "Individual Data Point",
                },
                {
                    id: 2,
                    lable: "Group Data Point",
                },
            ],
            groupDataPointList:[]
        }
    },
    created(){
        
        this.checkRadioButtonOnDefault()
        this.getAddedDatapointDetails()
         if(this.isAddDatapointClicked && this.addDatapointDetails.isinrepeatinggroup !== undefined && this.addDatapointDetails.datapointgroupid !== 0){
            this.selectedGroupId=this.addDatapointDetails.dataPointGroupId
            this.displayGroupKey++
            this.checkRadioButtonOnDefaultOnAddDataPoint()
            if(this.addDatapointDetails.sortOrders.length > 0 && this.groupDataPointList.length === 0){
                this.groupDataPointList=this.addDatapointDetails.sortOrders
            }
        }else{
            if(this.isAddDatapointClicked){
              this.checkRadioButtonOnDefaultOnAddDataPoint()
            }
            this.selectedGroupId=this.selectedDataPointGroupId
            this.displayGroupKey++
        }
        if(!this.isAddDatapointClicked){
            if(this.selectedDataPoint.sortorders !== null && this.selectedDataPoint.sortorders.length > 0){
                this.groupDataPointList=this.selectedDataPoint.sortorders
            }
            
        }
    },
    methods:{
        getAddedDatapointDetails(){
            if(this.isAddDatapointClicked){
                this.addedDataPointObject=this.addDatapointDetails
            }
        },
        checkRadioButtonOnDefaultOnAddDataPoint(){
            if(Object.keys(this.addDatapointDetails).length > 0){
                // if(this.addDatapointDetails.isinrepeatinggroup){
                //     this.switchRadioButtonType=2
                // }else{
                //     this.switchRadioButtonType=1
                // }
                this.switchRadioButtonType=1
                this.disableGroupsDropDown=false
                this.onRadioButtonChange()
            }else{
                this.disableGroupsDropDown=false
                this.switchRadioButtonType=0
            }
        },
        checkRadioButtonOnDefault(){
            if(Object.keys(this.selectedDataPoint).length > 0){
                // if(this.selectedDataPoint.isinrepeatinggroup){
                //     this.switchRadioButtonType=2
                // }else{
                //     this.switchRadioButtonType=1
                // }
                this.switchRadioButtonType=1
                this.disableGroupsDropDown=true
                this.onRadioButtonChange()
            }else{
                this.disableGroupsDropDown=false
                this.switchRadioButtonType=0
            }
        },
        disableRadioButton(buttonTypes){
             if(Object.keys(this.selectedDataPoint).length > 0){
                 if(buttonTypes.lable=== "Individual Data Point"){
                    if(this.selectedDataPoint.isinrepeatinggroup){
                        return true
                    }else{
                        return false
                    }
                }else{
                    if(this.selectedDataPoint.isinrepeatinggroup){
                        return false
                    }else{
                        return true
                    }
                }
             }else{
                 return false
             }
            
        },
        sortableData(){
            for(let i=0;i<this.groupDataPointList.length;i++){
                this.groupDataPointList[i].sortorder = i+1
            }
            this.$emit("groupDataPointsSorted",this.groupDataPointList)
        },
        onGroupChange(){
            if(this.isAddDatapointClicked){
                if(this.addDatapointDetails.dataPointName.length>0 && this.addDatapointDetails.dataTypeId>0 && this.addDatapointDetails.definitions.length>0){
                    this.addedDataPointObject.dataPointGroupId=parseInt(this.selectedGroupId)
                }
            }
            this.$emit("groupChanged",this.selectedGroupId)
        },
        onRadioButtonChange(){
            let isRepeatingGroup
            isRepeatingGroup=false
            // if(this.switchRadioButtonType == 1){
            //     isRepeatingGroup=false
                 
            // }else if(this.switchRadioButtonType == 2){
            //      isRepeatingGroup=true
                 
            // }
            if(this.isAddDatapointClicked){
                if(this.addDatapointDetails.isinrepeatinggroup !== undefined){
                    if(this.addDatapointDetails.isinrepeatinggroup !== isRepeatingGroup){
                        this.addDatapointDetails.dataPointGroupId=0
                        this.addDatapointDetails.sortOrders=[]
                        this.selectedGroupId=null
                        this.groupDataPointList=[]
                    }
                }
                this.addDatapointDetails.isinrepeatinggroup=isRepeatingGroup
               
            }
            this.$emit("groupTypeRadioButtonChange",isRepeatingGroup)
            
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";
.radioButtonGroup {
    display: inline-block;
    width: 280px;
    padding: 0px;
    margin-top: $mds-space-half-x;
}

.mainSection {
    margin-top: 16px;
}

.grouping-list,
.grouping-list_group .grouping_list_grpTitle {
    display:block;
    border:1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
}
.grouping-list_grpblk {
    width: 100%;
}
.caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}
.caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
}
.caret-down::before {
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari */
    transform: rotate(90deg);  
}
.grouping-list.activeCol .grouping-list_group {
    display: block;
}
.grouping-list_group {
    display: none;
}
.grouping_list_grpTitle,
.grouping-list-checkbox {
    display: flex;
}
.grouping-list_group .grouping_list_grpTitle {
    margin-left: 28px;
    width: 96%;
}
.grouping_list_grpTitle,
.grouping-list_group .grouping_list_grpTitle {
    cursor: move;
}

.grouping-list-dragdrop-button{
    margin-right: 5px;
    display: inline-block;
}
.groupingMainSection::v-deep .readOnlyInput .mds-input___VueMDS3Demo{
    
    box-shadow: none;
    background: none;
    padding-left: 0;
    display: none;
}

.groupingTitle {
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
    margin-bottom: 16px;
}
</style>