<template>
    <div id="dataModalContainer">
        <data-model
            :selectTab="selectTab"
            :tabsContent="tabsContent"
            :treeViewList="treeViewItemsList"
            :displayLoader="displayLoader"
            :dataPointSelected="clickedDataPoint"
            :addDatapointDetails="saveDataPoint"
            :isAddDatapointClicked="isAddDatapointClicked"
            :datapointGroupSelected="clickedDataPointGroup"
            :languagelist="dataModelLanguagesList"
            :dataTypeList="dataModelDataTypesList"
            :groupListAvailable="availableGroupsList"
            :datapointListInGroup="groupDataPoints"
            :selectedDataPointGroupId="selectedDataPointGroupId"
            :dataTypeValueDropDown="dataTypeValueDropDown"
            :dataTypeValueTextarea="dataTypeValueTextarea"
            :getValidationsValue="getValidationsValue"
            :definitions="definitions"
            :adminUser="adminUser"
            :entityLevelName="entityLevelName"
            :addButtonDisable="addButtonDisable"
            :saveDataPoint="saveDataPoint"
            :showRightSection="showRightSection"
            :isDatapointSearched="isDatapointSearched"
            :buttonName="buttonName"
            @changeTextArea="changeTextArea"
            @setActiveItem="setActiveItem"
            @onTreeEntityTypeClick="onTreeEntityTypeClick"
            @onDataPointClick="onDataPointClick"
            @addDataPointButtonClick="addDataPointButtonClick"
            @addDataPointGroupButtonClick="addDataPointGroupButtonClick"
            @addButton="addButton"
            @groupDetailsChanged="groupDetailsChanged"
            @groupTypeRadioButtonChange="groupTypeRadioButtonChange"
            @onClickDataPointName="onClickDataPointName"
            @onDataPointType="onDataPointType"
            @showNACheckbox="showNACheckbox"
            @changeSubType="changeSubType"
            @onDataPointValueEnum="onDataPointValueEnum"
            @onDataPointValueRef="onDataPointValueRef"
            @multiLanguageClickEvent="multiLanguageClickEvent"
            @downArrow="downArrow"
            @upArrow="upArrow"
            @deleteDefinition="deleteDefinition"
            @deleteDataPoint="deleteDataPoint"
            @groupDataPointsSorted="groupDataPointsSorted"
            @datapointSearched="searchDataPoints"
            @emptySearchedDataPoints="emptySearchedDataPoints"
        ></data-model>
        <dialog-popup
            :toggleDialog="toggleDialog"
            :title="dialogTitle"
            :sub-title="subTitle"
            :selectedAction="selectedAction"
            checkboxLabel="Yes, delete this data point."
            deleteLabel="data point"
            @cancel="closeDialogPopup"
            @emitDeletePopup="deletePopupModal"
        >
        </dialog-popup>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
    </div>
</template>

<script>
import store from "@/store";
import get from "lodash/get";
import { DATA_MODEL_ACTIONS } from "@/store/modules/dataModel/contants";
import DataModel from "../components/DataModel.vue";
import { mapActions, mapGetters,mapState} from "vuex";
import DialogPopup from "@/components/common_components/DialogPopup.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    name: "data-modal-container",
    beforeCreate() {
        this.$store = store;
    },
    components: {
        DataModel,
        DialogPopup,
        NotificationComponent,
    },

    data(){
        return {
            isDatapointSearched:false,
            displayLoader:false,
            showLoader:false,
            selectTab: "attributes",
            tabsContent: [
                {
                    text: "Attributes",
                    id: "attributes",
                    active: true,
                },
                {
                    text: "Display & Grouping",
                    id: "grouping",
                    active: false,
                },
                {
                    text: "Validations",
                    id: "validations",
                    active: false,
                },
            ],
            treeViewItemsList:[],
            entityTypeList:[],
            listObject:{},
            clickedDataPoint:{},
            clickedDataPointGroup:{},
            availableGroupsList:[],
            groupDataPoints:[],
            selectedDataPointGroupId:null,
            dataTypeValueDropDown:[],
            dataTypeValueTextarea:"",
            getValidationsValue:[],
            definitions:[],
            definitionData:[],
            datapointdependencytypeid:0,
            adminUser:false,
            entityLevelName:"",
            entityLevelId:0,
            isAddDatapointClicked:false,
            definitionTextArea:"",
            addButtonDisable:true,
            dataTypeValueLabel:"",
            buttonName:"Next",
            // delete data point
            toggleDialog: false,
            selectedAction: "",
            subTitle: "",
            dialogTitle: "",
            deleteDataPointId:0,
            entityTypeItem:{},

            //Notification
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            paginationComponentKey:0,

            //save data point
            saveDataPoint: {
                dataPointId: null,
                dataTypeId: 0,
                dataTypeLabel: "",
                dataPointDependencyTypeId: 0,
                dataPointGroupId: 0,
                entityTypeId: 0,
                referencedEntityTypeId: null,
                dataPointName: "",
                dataPointDescription: "",
                dataPointExportName: "",
                arrayLength: 1,
                enumId: null,
                disabledAsDefault: false,
                showInFilter: true,
                showNACheckbox: false,
                definitions: [],
                validations: [],
                autoMappings: [],
                sortOrders: [],
            },
            
            showRightSection:false,

        }
    },
    watch:{
        showLoader(val){
            this.displayLoader=val
        },
        definitionData(val){
            if(val){
                this.definitions=[]
                this.test()
            }else{
                this.definitionData=[]
                this.test()
            }
        },
        tabsContent(value){
            if(value){
                this.tabsContent.forEach((item) => {
                    if(this.selectTab !== item.id){
                        document.getElementById(item.id).style.pointerEvents = "none";
                    }                
                })
            }
        }
    },
    computed:{
         ...mapGetters("entity", ["getEntityTypes"]),
         ...mapState({
            dataModelDataPointList(state) {
                return get(state, "dataModel.dataModelDatapointList.dataModelDatapointList", []);
            },
            dataModelDataPointListLoader(state) {
                return get(state, "dataModel.dataModelDatapointList.__loading__", false);
            },
            dataModelDataPointListError(state) {
                return get(state, "dataModel.dataModelDatapointList.__error__", null);
            },

            //Language
            dataModelLanguagesList(state) {
                return get(state, "dataModel.dataModelLanguageList.dataModelLanguageList", []);
            },
            dataModelLanguagesListLoader(state) {
                return get(state, "dataModel.dataModelLanguageList.__loading__", false);
            },
            dataModelLanguagesListError(state) {
                return get(state, "dataModel.dataModelLanguageList.__error__", null);
            },

            //Data Type
            dataModelDataTypesList(state) {
                return get(state, "dataModel.dataModelDataTypeList.dataModelDataTypeList", []);
            },
            dataModelDataTypesListLoader(state) {
                return get(state, "dataModel.dataModelDataTypeList.__loading__", false);
            },
            dataModelDataTypesListError(state) {
                return get(state, "dataModel.dataModelDataTypeList.__error__", null);
            },

            //Datapoint Details
            dataModelDataPointDetails(state) {
                return get(state, "dataModel.dataModelDatapointDetails.dataModelDatapointDetails", []);
            },
            dataModelDataPointDetailsLoader(state) {
                return get(state, "dataModel.dataModelDatapointDetails.__loading__", false);
            },
            dataModelDataPointDetailsError(state) {
                return get(state, "dataModel.dataModelDatapointDetails.__error__", null);
            },

             //Available Groups
            dataModelAvailableGroupsList(state) {
                return get(state, "dataModel.dataModelAvailableGroupsList.dataModelAvailableGroupsList", []);
            },
            dataModelAvailableGroupsListLoader(state) {
                return get(state, "dataModel.dataModelAvailableGroupsList.__loading__", false);
            },
            dataModelAvailableGroupsListError(state) {
                return get(state, "dataModel.dataModelAvailableGroupsList.__error__", null);
            },

             //Datapoint from selected Groups
            dataModelDataPointListFromSelectedGroup(state) {
                return get(state, "dataModel.dataModelDataPointListFromSelectedGroup.dataModelDataPointListFromSelectedGroup", []);
            },
            dataModelDataPointListFromSelectedGroupLoader(state) {
                return get(state, "dataModel.dataModelDataPointListFromSelectedGroup.__loading__", false);
            },
            dataModelDataPointListFromSelectedGroupError(state) {
                return get(state, "dataModel.dataModelDataPointListFromSelectedGroup.__error__", null);
            },
            //Enum Type
            dataModelEnumTypesList(state) {
                return get(state, "dataModel.dataModelEnumTypeList.dataModelEnumTypeList", []);
            },
            dataModelEnumTypesListLoader(state) {
                return get(state, "dataModel.dataModelEnumTypeList.__loading__", false);
            },
            dataModelEnumTypesListError(state) {
                return get(state, "dataModel.dataModelEnumTypeList.__error__", null);
            },

            //Reference Type
            dataModelReferenceTypesList(state) {
                return get(state, "dataModel.dataModelReferenceTypeList.dataModelReferenceTypeList", []);
            },
            dataModelReferenceTypesListLoader(state) {
                return get(state, "dataModel.dataModelReferenceTypeList.__loading__", false);
            },
            dataModelReferenceTypesListError(state) {
                return get(state, "dataModel.dataModelReferenceTypeList.__error__", null);
            },

            //Enum Value
            dataModelEnumValuesList(state) {
                return get(state, "dataModel.dataModelEnumValueList.dataModelEnumValueList", []);
            },
            dataModelEnumValuesListLoader(state) {
                return get(state, "dataModel.dataModelEnumValueList.__loading__", false);
            },
            dataModelEnumValuesListError(state) {
                return get(state, "dataModel.dataModelEnumValueList.__error__", null);
            },

            //Reference Value
            dataModelReferenceValuesList(state) {
                return get(state, "dataModel.dataModelReferenceValueList.dataModelReferenceValueList", []);
            },
            dataModelReferenceValuesListLoader(state) {
                return get(state, "dataModel.dataModelReferenceValueList.__loading__", false);
            },
            dataModelReferenceValuesListError(state) {
                return get(state, "dataModel.dataModelReferenceValueList.__error__", null);
            },

            //Validations Values
            dataModelValidationList(state) {
                return get(state, "dataModel.dataModelValidationsList.dataModelValidationsList", []);
            },
            dataModelValidationListLoader(state) {
                return get(state, "dataModel.dataModelValidationsList.__loading__", false);
            },
            dataModelValidationListError(state) {
                return get(state, "dataModel.dataModelValidationsList.__error__", null);
            },

            //Save Data Point
            dataModelSaveDataPoints(state) {
                return get(state, "dataModel.dataModelSaveDataPoint.dataModelSaveDataPoint", []);
            },
            dataModelSaveDataPointsLoader(state) {
                return get(state, "dataModel.dataModelSaveDataPoint.__loading__", false);
            },
            dataModelSaveDataPointsError(state) {
                return get(state, "dataModel.dataModelSaveDataPoint.__error__", null);
            },

            //Delete Data Point
            dataModelDeleteDataPoints(state) {
                return get(state, "dataModel.dataModelDeleteDataPoint.dataModelDeleteDataPoint", []);
            },
            dataModelDeleteDataPointsError(state) {
                return get(state, "dataModel.dataModelDeleteDataPoint.__error__", null);
            },

            //search Data Point
            dataModelSearchDataPoints(state) {
                return get(state, "dataModel.dataModelSearchDataPoint.dataModelSearchDataPoint", []);
            },
            dataModelSearchDataPointsLoader(state) {
                return get(state, "dataModel.dataModelSearchDataPoint.__loading__", false);
            },
            dataModelSearchDataPointsError(state) {
                return get(state, "dataModel.dataModelSearchDataPoint.__error__", null);
            },
        }),
    },

    async mounted(){
        const getAllPermissions = this.$store.state.auth.permissions;
        const checkAdmin = getAllPermissions.includes('alert-email-section') || getAllPermissions.includes('*');
        this.adminUser = checkAdmin;
        this.showLoader=true
        if(this.getEntityTypes.length == 0){
            await this.fetchEntityTypes()
            await this.createTreeviewEntityTypeObject()
        }else{
            await this.createTreeviewEntityTypeObject()
        }
        await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_LANGUAGES_LIST)
        await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_DATA_TYPES_LIST)
        this.test();
        this.showLoader=false
    },
    methods : {
        ...mapActions("entity", ["fetchEntityTypes"]),
        async setActiveItem(event) {               
            this.tabsContent.forEach((item) => {
                if (item.id === event.currentTarget.id) {
                    this.selectTab = event.currentTarget.id;
                    item.active = true;
                }else {
                    item.active = false;
                }
            });
            if (event.currentTarget.id == "attributes") {
                this.buttonName = "Next";
                document.getElementById(this.tabsContent[1].id).style.pointerEvents = "none";
                document.getElementById(this.tabsContent[1].id).style.opacity = "0.4";
                document.getElementById(this.tabsContent[2].id).style.pointerEvents = "none";
                document.getElementById(this.tabsContent[2].id).style.opacity = "0.4";
                this.checkedSaveNextButtonCondition();
            }else if (event.currentTarget.id == "grouping") {
                this.buttonName = "Next";
                this.selectedDataPointGroupId=this.clickedDataPoint.datapointgroupid
                document.getElementById(this.tabsContent[0].id).style.pointerEvents = "auto";
                document.getElementById(this.tabsContent[0].id).style.opacity = "1";
                document.getElementById(this.tabsContent[2].id).style.pointerEvents = "none";
                document.getElementById(this.tabsContent[2].id).style.opacity = "0.4";
                this.checkedSaveNextButtonCondition();
            } else if (event.currentTarget.id == "validations") {
                this.showLoader = true;
                document.getElementById(this.tabsContent[0].id).style.pointerEvents = "auto";
                document.getElementById(this.tabsContent[0].id).style.opacity = "1";
                document.getElementById(this.tabsContent[1].id).style.pointerEvents = "auto";
                document.getElementById(this.tabsContent[1].id).style.opacity = "1";
                if(Object.keys(this.clickedDataPoint).length !== 0){
                    this.buttonName = "Save"
                }else{
                    this.buttonName = "Add"
                }
                await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_VALIDATIONS_LIST)
                this.validationsValueReponse();
                this.showLoader = false;
            }
            
        },
        async createTreeviewEntityTypeObject(){
            this.listObject={
                name: this.getEntityTypes[0].entitytypedescription,
                entitytypeid:this.getEntityTypes[0].entitytypeid,
                showChildren:true,
                itemLevel:1,
                children: []
            }
           
            this.entityTypeList=[]
            for(let k=0;k<this.getEntityTypes.length;k++){
                let obj={
                    entitytypeid:this.getEntityTypes[k].entitytypeid,
                    entitytypedescription:this.getEntityTypes[k].entitytypedescription,
                    parentEntityTypeId:null,
                }
                this.entityTypeList.push(obj)
            }
            for(let m=0;m<this.entityTypeList.length;m++){
                for(let n=0;n<this.getEntityTypes.length;n++){
                    if(this.entityTypeList[m].entitytypeid == this.getEntityTypes[n].childentityid){
                        this.entityTypeList[m].parentEntityTypeId=this.getEntityTypes[n].entitytypeid
                        break;
                    }else{
                        if(m ==this.entityTypeList.length-1){
                            this.entityTypeList[m].parentEntityTypeId=null
                        }
                    }
                }
            }
            await this.setTreeObject(this.listObject)
            this.treeViewItemsList.push(this.listObject)

        },
        setTreeObject(item){
             let datapointObject={
                        name: "Data points",
                        showChildren:false,
                        itemLevel:1,
                        entitytypeid:null,
                        children: [],
                        entityLevel:item.name,
                        entityLevelTypeId:item.entitytypeid
                    }
                    // let datapointGroupObject={
                    //     name: "Datapoint group",
                    //     showChildren:false,
                    //     itemLevel:1,
                    //     entitytypeid:null,
                    //     children: [],
                    //     entityLevel:item.name,
                    //     entityLevelTypeId:item.entitytypeid
                    // }
            for(let i=0;i<this.entityTypeList.length;i++){
                if(item.entitytypeid == this.entityTypeList[i].parentEntityTypeId){
                    item.children.push(datapointObject)
                    // item.children.push(datapointGroupObject)
                     let childListObject = {
                            name: this.entityTypeList[i].entitytypedescription,
                            entitytypeid:this.entityTypeList[i].entitytypeid,
                            showChildren:true,
                            itemLevel:1,
                            children: []
                        }
                        item.children.push(childListObject)
                        break;
                }else{
                    if(i == this.entityTypeList.length-1){
                        item.children=[]
                        item.children.push(datapointObject)
                        // item.children.push(datapointGroupObject)
                    }
                }
            }
            if(item.children.length > 1){
                this.setTreeObject(item.children[1])
            }
        },
        async onTreeEntityTypeClick(entityTypeItem){
            this.showLoader=true
            let entityTypeId
            if(entityTypeItem.entitytypeid != null){
                entityTypeId=entityTypeItem.entitytypeid
            }else{
                entityTypeId=entityTypeItem.entityLevelTypeId
            }
           await this.fetchTreeViewDataPoints(entityTypeId)
                let datapointList = this.dataModelDataPointList
                let datapointGroup = []
                for(let i=0;i<datapointList.length;i++){
                    datapointList[i]["entitytypeid"]=null
                    datapointList[i]["showChildren"]=false
                    datapointList[i]["itemLevel"]=0
                    datapointList[i]["children"]=[]
                    datapointList[i]["entityLevel"]=entityTypeItem.name
                     datapointList[i]["entityLevelTypeId"]=entityTypeId
                     datapointList[i]["isHover"]=false;
                    if(datapointList[i].datapointgroups != null){
                        for(let j=0;j<datapointList[i].datapointgroups.length;j++){
                            datapointList[i].datapointgroups[j]["entitytypeid"]=null
                            datapointList[i].datapointgroups[j]["showChildren"]=false
                            datapointList[i].datapointgroups[j]["itemLevel"]=0
                            datapointList[i].datapointgroups[j]["children"]=[]
                            datapointList[i].datapointgroups[j]["entityLevel"]=entityTypeItem.name
                            datapointList[i].datapointgroups[j]["entityLevelTypeId"]=entityTypeId
                            datapointList[i].datapointgroups[j]["isHover"]=false;
                        }
                        datapointGroup.push(datapointList[i])
                    }
                }
                await this.addDataPointInTreeview(entityTypeItem,this.listObject,datapointList,datapointGroup,entityTypeId)
                this.treeViewItemsList=[]
                this.treeViewItemsList.push(this.listObject)
                this.showLoader=false
        },
        addDataPointInTreeview(entityTypeItem,item,datapointList,datapointGroup,entityTypeId){
            if(item.entitytypeid == entityTypeId){
                if(entityTypeItem.isDeleted){
                    item.children[0].children = [];
                    // item.children[1].children=[];
                }
                for(let i=0;i<datapointList.length;i++){
                    if(datapointList[i].datapointgroups == null){
                        item.children[0].children.push(datapointList[i])
                    }else{
                        for(let j=0;j<datapointList[i].datapointgroups.length;j++){
                            item.children[0].children.push(datapointList[i].datapointgroups[j])
                        }
                    }
                }
                // item.children[1].children=datapointGroup
            }else{
                if(item.children.length > 0){
                    this.addDataPointInTreeview(entityTypeItem,item.children[1],datapointList,datapointGroup,entityTypeId)
                }
            }
        },
        async fetchTreeViewDataPoints(entityTypeId){
            let data={
                entityTypeId:entityTypeId
            }
            await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_DATAPOINT_LIST,{
                    data,
            })
        },
        async onDataPointClick(item){
            this.isAddDatapointClicked=false
            this.clearSaveDataPointDetails()
            this.getEntityTypes.forEach(value => {
                if(value.entitytypeid === item.entityLevelTypeId){
                    item.entityLevel = value.entitytypedescription;
                }
            })
            this.entityLevelName = item.entityLevel;
            this.entityLevelId = item.entityLevelTypeId;
            if(item.id !== undefined){
                this.buttonName = "Next";
                this.definitionTextArea = "";
                this.showRightSection = true;
                this.tabsContent.forEach((item) => {
                    if (item.id ===  "attributes") {
                        this.selectTab = item.id;
                        item.active = true;
                    }else {
                        item.active = false;
                    }
                });
                this.showLoader = true
                if(item.datapointgroups == null){
                    let data={
                            dataPointId : item.id
                    }
                    await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_DATAPOINT_DETAILS,{
                            data,
                    })
                    let response = this.dataModelDataPointDetails.result;
                    response["entityLevel"]=item.entityLevel;
                    this.saveDataPoint.dataPointId = response.datapointid;
                    this.saveDataPoint.dataTypeId = response.datatypeid;
                    this.saveDataPoint.dataPointDependencyTypeId = response.datapointdependencytypeid;
                    this.saveDataPoint.dataPointGroupId = response.datapointgroupid;
                    this.saveDataPoint.entityTypeId = response.entitytypeid;
                    this.saveDataPoint.referencedEntityTypeId = response.referencedentitytypeid;
                    this.saveDataPoint.dataPointName = response.datapointname;
                    this.saveDataPoint.dataPointDescription = response.datapointdescription;
                    this.saveDataPoint.dataPointExportName = response.datapointexportname;
                    this.saveDataPoint.arrayLength = response.arraylength;
                    this.saveDataPoint.enumId = response.enumid;
                    this.saveDataPoint.disabledAsDefault = response.disabledasdefault;
                    this.saveDataPoint.showInFilter = response.showinfilter;
                    this.saveDataPoint.showNACheckbox = response.shownacheckbox;
                    this.saveDataPoint.definitions = response.definitions;
                    this.saveDataPoint.validations = response.validations;
                    this.saveDataPoint.autoMappings = response.automappings;
                    this.saveDataPoint.sortOrders = response.sortorders;

                    if(response.definitions.length == 0){
                        response.definitions = undefined
                    }else{
                        response.definitions.forEach(defi=>{
                            this.definitionTextArea = defi.datadefinition
                        })
                    }
                    if(response.enumid != null){
                        await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_ENUM_TYPES_LIST)
                        this.dataTypeValueDropDown = this.dataModelEnumTypesList;
                        let data=[];
                        data.push(response.enumid)
                        await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_ENUM_VALUES_LIST, { data })
                        let enumvalue = this.dataModelEnumValuesList
                        let array=[]
                        enumvalue.forEach(item=>{
                            item.values.forEach(value=>{
                                array.push(value.text)
                            })
                        })
                        this.dataTypeValueTextarea = array.join(',')
                    }
                    if(response.referencedentitytypeid != null){
                        await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_REFERENCE_TYPES_LIST)
                        this.dataTypeValueDropDown = this.dataModelReferenceTypesList;
                        let data=[];
                        data.push(response.referencedentitytypeid)
                        await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_REFERENCE_VALUES_LIST, { data } )
                        let refvalue = this.dataModelReferenceValuesList
                        let array=[]
                        refvalue.forEach(item=>{
                            item.values.forEach(value=>{
                                array.push(value.text)
                            })
                        })
                        this.dataTypeValueTextarea = array.join(',')
                    }
                    this.definitionData = response.definitions
                    this.clickedDataPoint = response
                    this.showLoader = false
                }else{
                    this.clickedDataPointGroup=item
                    this.showLoader = false
                }
            }
            this.checkedSaveNextButtonCondition();
        },
         async getAvailableGroups(isRepeatingGroup){
                let data={
                        isRepeatingGroup : isRepeatingGroup
                }
                await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_AVAILABLE_GROUPS,{
                        data,
                })
                let response = this.dataModelAvailableGroupsList;
                this.availableGroupsList = response
        },
        async getDataPointFromSelectedGroup(groupId,entityTypeId){
             let data={
                    groupId:groupId,
                    entityTypeId:entityTypeId
                }
                await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_DATAPOINT_FROM_SELECTED_GROUP,{
                        data,
                })
                let response = this.dataModelDataPointListFromSelectedGroup.result;
                if(response){
                 this.groupDataPoints=response
                }else{
                    this.groupDataPoints=[]
                }
                if(this.groupDataPoints){
                    for(let i=0;i<this.groupDataPoints.length;i++){
                        this.groupDataPoints[i]["datapointgroupid"]=groupId
                        this.groupDataPoints[i]["sortorder"]=this.groupDataPoints[i].order
                    }
                }
                if(this.isAddDatapointClicked){
                    let addDataPointInGroup={
                        datapointid: this.saveDataPoint.dataPointId,
                        datapointname: this.saveDataPoint.dataPointName,
                        datapointgroupid:groupId,
                        sortorder: 0,
                    }
                    this.groupDataPoints.push(addDataPointInGroup)
                    for(let i=0;i<this.groupDataPoints.length;i++){
                        this.groupDataPoints[i].sortorder=i+1
                    }
                    this.saveDataPoint.sortOrders=this.groupDataPoints
                }
                

        },
        async groupDetailsChanged(groupId){
            this.showLoader=true
            this.selectedDataPointGroupId= parseInt(groupId)
            this.saveDataPoint.dataPointGroupId = this.selectedDataPointGroupId
            await this.getDataPointFromSelectedGroup(groupId,this.saveDataPoint.entityTypeId)
            this.checkedSaveNextButtonCondition();
            this.showLoader=false
        },
        async groupTypeRadioButtonChange(isRepeatingGroup){
            this.showLoader=true
            await this.getAvailableGroups(isRepeatingGroup)
            if(this.clickedDataPoint.isinrepeatinggroup && this.selectedDataPointGroupId !== null){
                if(this.clickedDataPoint.sortorders !== null && this.clickedDataPoint.sortorders.length > 0){
                    this.groupDataPoints = this.clickedDataPoint.sortorders
                }else{
                    await this.getDataPointFromSelectedGroup(this.selectedDataPointGroupId,this.clickedDataPoint.entitytypeid)
                }
            }
            if(this.isAddDatapointClicked){
                if(this.saveDataPoint.isinrepeatinggroup && this.saveDataPoint.dataPointGroupId !== 0 ){
                    if(this.saveDataPoint.sortOrders.length === 0 || parseInt(this.saveDataPoint.sortOrders[0].datapointgroupid) !== parseInt(this.saveDataPoint.dataPointGroupId)){
                        this.groupDataPoints=[]
                        await this.getDataPointFromSelectedGroup(this.saveDataPoint.dataPointGroupId,this.saveDataPoint.entityTypeId)
                    }else{
                        this.groupDataPoints = this.saveDataPoint.sortOrders
                    }
                }
            }
            if(Object.keys(this.clickedDataPoint).length === 0 && !this.isAddDatapointClicked){
                this.groupDataPoints=[]
                this.selectedDataPointGroupId=null
            }
            this.showLoader=false
        },
        addDataPointButtonClick(data){
            this.buttonName = "Next";
            this.showRightSection = true;
            this.tabsContent.forEach((item) => {
                    if (item.id ===  "attributes") {
                        this.selectTab = item.id;
                        item.active = true;
                    }else {
                        item.active = false;
                    }
                });
            this.isAddDatapointClicked=true
            this.clearSaveDataPointDetails()
            this.getEntityTypes.forEach(value => {
                if(value.entitytypeid === data.entityLevelTypeId){
                    data.entityLevel = value.entitytypedescription;
                }
            })
            this.entityLevelName = data.entityLevel;
            this.entityLevelId = data.entityLevelTypeId
            this.clickedDataPoint={}
            this.selectedDataPointGroupId=null
            this.definitionData = [];
            this.dataTypeValueTextarea = "";
            this.definitions = [];
            this.definitionTextArea = "";
            this.addButtonDisable = true;
            this.test()
        },
        addDataPointGroupButtonClick(data){
             console.log(data);
        },

        // Attribute functions decleartion start
        onClickDataPointName(value){
            this.saveDataPoint.dataPointName = value;
            this.saveDataPoint.dataPointDescription = value;
            this.saveDataPoint.dataPointExportName = value;
            if(this.saveDataPoint.sortOrders.length > 0){
                for(let i=0;i<this.saveDataPoint.sortOrders.length;i++){
                    if(this.saveDataPoint.sortOrders[i].datapointid === null){
                        this.saveDataPoint.sortOrders[i].datapointname=value
                        break;
                    }
                }
            }
            this.checkedSaveNextButtonCondition();
        },
        async onDataPointType(value){
            this.dataTypeValueTextarea = "";
            this.dataTypeValueLabel = value.label;
            this.saveDataPoint.enumId = null;
            this.saveDataPoint.referencedEntityTypeId = null;
            if(value.label == 'Enum'){
                this.showLoader = true;
                await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_ENUM_TYPES_LIST)
                this.dataTypeValueDropDown = this.dataModelEnumTypesList;
                this.showLoader = false;
            }
            if(value.label == 'Reference'){
                this.showLoader = true;
                await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_REFERENCE_TYPES_LIST)
                this.dataTypeValueDropDown = this.dataModelReferenceTypesList;
                this.showLoader = false;
            }
            this.saveDataPoint.dataTypeId = value.datatypeid;
            this.saveDataPoint.dataTypeLabel = value.label
            this.checkedSaveNextButtonCondition();
        },
        showNACheckbox(value) {
            this.saveDataPoint.showNACheckbox = value;
        },
        changeSubType(value){
            this.saveDataPoint.arrayLength = value;
        },
        async onDataPointValueEnum(value){
            this.showLoader = true;
            let data=[];
            data.push(value)
            await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_ENUM_VALUES_LIST, { data })
            let enumvalue = this.dataModelEnumValuesList
            let array=[]
            enumvalue.forEach(item=>{
                item.values.forEach(value=>{
                    array.push(value.text)
                })
            })
            this.dataTypeValueTextarea = array.join(',');
            this.saveDataPoint.enumId = value;
            this.checkedSaveNextButtonCondition();
            this.showLoader = false;
        },
        async onDataPointValueRef(value){
            this.showLoader = true;
            let data=[];
            data.push(value)
            await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATA_MODEL_REFERENCE_VALUES_LIST, { data } )
            let refvalue = this.dataModelReferenceValuesList
            let array=[]
            refvalue.forEach(item=>{
                item.values.forEach(value=>{
                    array.push(value.text)
                })
            })
            this.dataTypeValueTextarea = array.join(',')
            this.saveDataPoint.referencedEntityTypeId = value;
            this.checkedSaveNextButtonCondition();
            this.showLoader = false;
        },
        multiLanguageClickEvent(value){
            var obj={
                definitionname:value.text,
                ietflanguagetag:value.value,
                isExpand:false,
                description: ""
            }
            this.definitions.push(obj);
            let uniq={}
            var temp = this.definitions.filter(obj => !uniq[obj.definitionname] && (uniq[obj.definitionname] = true));
            this.definitions = temp;
        },
        upArrow(value){
            this.definitions.forEach(item=>{
                if(item.definitionname == value.definitionname){
                    item.isExpand = false;
                }
            })
        },
        downArrow(value){
            this.definitions.forEach(item=>{
                if(item.definitionname == value.definitionname){
                    item.isExpand = true;
                }else{
                    item.isExpand = false;
                }
            })
        },
        changeTextArea(event, value){
            this.definitionTextArea = event
            console.log(value); 
            this.checkedSaveNextButtonCondition();
        },
        deleteDefinition(value, index){
            console.log(value);
            this.definitions.splice(index, 1)
        },

        test(){
            let obj={}
            let uniq={}
            if(this.definitionData.length != 0){
                this.definitionData.forEach(item =>{
                    this.dataModelLanguagesList.forEach(language => {
                        if(item.ietflanguagetag == 'zz'){
                            item.ietflanguagetag = 'en'
                        }
                        if(item.ietflanguagetag == language.value){
                            obj={
                                    definitionname : language.text,
                                    ietflanguagetag : item.ietflanguagetag,
                                    isExpand : false,
                                    description : item.datadefinition
                                }
                            this.definitions.push(obj);
                        }
                    })
                })
                this.definitions.push(obj);
            }else{
                obj={
                        definitionname : "English",
                        ietflanguagetag : "en",
                        isExpand : false,
                        description : ""
                    }
            }
            this.definitions.push(obj);
            this.definitions = this.definitions.filter(obj => !uniq[obj.definitionname] && (uniq[obj.definitionname] = true));
        },
        // Attribute functions decleartion end

        async validationsValueReponse(){
            let response = this.dataModelValidationList;
            this.getValidationsValue=[]
            let validationList=[]
            for (let i = 0; i < response.length; i++) {
                let data = {};
                data.value = response[i].datapointvalidationoperationid;
                data.text = response[i].datapointvalidationdescription;
                data.label=response[i].label
                data.isvaluedependent=response[i].isvaluedependent
                validationList.push(data);
            }
            this.getValidationsValue=validationList
        },

        deleteDataPoint(item){
            this.subTitle = `This action cannot be undone and the data point will be deleted for all users with access`;
            this.dialogTitle = "Delete this data point?";
            this.selectedAction = "Delete";
            this.toggleDialog = true;
            this.deleteDataPointId = item.id;
            this.entityTypeItem = item;
        },

        // event for delete action
        async deletePopupModal() {
            let dataPointId = this.deleteDataPointId;
            this.toggleDialog = false;
            
            await this.$store.dispatch(
                DATA_MODEL_ACTIONS.DELETE_DATAPOINT,
                {dataPointId}
            );
            let response = this.dataModelDeleteDataPoints;
            const obj = {
                response,
                ntfnMsg: "The data point has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
                ntfnfailureMsg: this.dataModelDeleteDataPointsError,
            };

            this.notificationResponse(obj);
            this.deleteDataPointId = 0;
            if (this.dataModelDeleteDataPointsError == null) {
                this.entityTypeItem['isDeleted'] = true
                this.onTreeEntityTypeClick(this.entityTypeItem);
                this.showRightSection = false;
            }
        },
        // event for closing delete dialog popup
        closeDialogPopup() {
            this.toggleDialog = false;
        },

        // notification event
        notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

        passingDefinitionData(){
            let array=[];
            if(Object.keys(this.clickedDataPoint).length != 0){
                this.definitions.forEach(item=>{
                    let obj={};
                    obj["dataPointId"] = this.clickedDataPoint.datapointid;
                    obj["dataDefinition"] = item.description;
                    obj["ietfLanguageTag"] = item.ietflanguagetag;
                    array.push(obj);
                })
            }else {
                this.definitions.forEach(item=>{
                    let obj={};
                    obj["dataDefinition"] = item.description;
                    obj["ietfLanguageTag"] = item.ietflanguagetag;
                    array.push(obj);
                })
            }         
            this.saveDataPoint.definitions = array;
        },

        checkedSaveNextButtonCondition(){
            if(this.selectTab === 'attributes'){
                if(this.dataTypeValueLabel === "Enum"){
                    if(this.saveDataPoint.dataPointName !== "" && this.saveDataPoint.dataTypeId !== 0 && this.definitionTextArea !== "" && this.saveDataPoint.enumId !== null ){
                        this.addButtonDisable = false;
                        this.tabsEnabledAttributes();
                    }else{
                        this.addButtonDisable = true;
                        this.tabsDisabledAttributes();
                    }
                }
                if(this.dataTypeValueLabel === "Reference"){
                    if(this.saveDataPoint.dataPointName !== "" && this.saveDataPoint.dataTypeId !== 0 && this.definitionTextArea !== "" && this.saveDataPoint.referencedEntityTypeId !== null){
                        this.addButtonDisable = false;
                        this.tabsEnabledAttributes();
                    }else{
                        this.addButtonDisable = true;
                        this.tabsDisabledAttributes();
                    }
                } 
                if(this.dataTypeValueLabel !== "Enum" && this.dataTypeValueLabel !== "Reference"){
                    if(this.saveDataPoint.dataPointName !== "" && this.saveDataPoint.dataTypeId !== 0 && this.definitionTextArea !== ""){
                        this.addButtonDisable = false;
                        this.tabsEnabledAttributes();
                    }else{
                        this.addButtonDisable = true;
                        this.tabsDisabledAttributes();
                    }
                }
            }else if(this.selectTab === 'grouping'){
                if(this.saveDataPoint.dataPointGroupId !== 0){
                    this.addButtonDisable = false;
                    this.tabsEnabledGrouping();
                }else{
                    this.addButtonDisable = true;
                    this.tabsDisabledGrouping();
                }
            }else if(this.selectTab === 'validations'){
                this.tabsEnabledValidations();
            }
        },

        tabsEnabledAttributes(){
            document.getElementById(this.tabsContent[1].id).style.pointerEvents = "auto";
            document.getElementById(this.tabsContent[1].id).style.opacity = "1";
            if(this.saveDataPoint.dataPointGroupId !== 0){
                document.getElementById(this.tabsContent[2].id).style.pointerEvents = "auto";
                document.getElementById(this.tabsContent[2].id).style.opacity = "1";
            }else{
                document.getElementById(this.tabsContent[2].id).style.pointerEvents = "none";
                document.getElementById(this.tabsContent[2].id).style.opacity = "0.4";
            }
        },

        tabsDisabledAttributes(){
            document.getElementById(this.tabsContent[1].id).style.pointerEvents = "none";
            document.getElementById(this.tabsContent[1].id).style.opacity = "0.4";
            document.getElementById(this.tabsContent[2].id).style.pointerEvents = "none";
            document.getElementById(this.tabsContent[2].id).style.opacity = "0.4";
        },

        tabsEnabledGrouping(){
            document.getElementById(this.tabsContent[0].id).style.pointerEvents = "auto";
            document.getElementById(this.tabsContent[0].id).style.opacity = "1";
            document.getElementById(this.tabsContent[2].id).style.pointerEvents = "auto";
            document.getElementById(this.tabsContent[2].id).style.opacity = "1";
        },

        tabsDisabledGrouping(){
            document.getElementById(this.tabsContent[0].id).style.pointerEvents = "auto";
            document.getElementById(this.tabsContent[0].id).style.opacity = "1";
            document.getElementById(this.tabsContent[2].id).style.pointerEvents = "none";
            document.getElementById(this.tabsContent[2].id).style.opacity = "0.4";
        },

        tabsEnabledValidations(){
            document.getElementById(this.tabsContent[0].id).style.pointerEvents = "auto";
            document.getElementById(this.tabsContent[0].id).style.opacity = "1";
            document.getElementById(this.tabsContent[1].id).style.pointerEvents = "auto";
            document.getElementById(this.tabsContent[1].id).style.opacity = "1";
        },

        async addButton(){
            this.passingDefinitionData();
            this.saveDataPoint.entityTypeId = this.entityLevelId;
            if(this.buttonName === 'Save' || this.buttonName === 'Add'){
                let data={};
                 this.isAddDatapointClicked=false
                data = this.saveDataPoint;
                await this.$store.dispatch(DATA_MODEL_ACTIONS.SAVE_DATAPOINT, { data })
            }else{
                this.isAddDatapointClicked=true
            }
            if(this.selectTab === "attributes"){
                if(this.buttonName === 'Next'){
                    this.selectTab = "grouping";
                    this.tabsContent.forEach((item)=>{
                        if(this.selectTab === item.id){
                            item.active = true;
                        }else{
                            item.active = false;
                        }
                    })
                }
            }else if(this.selectTab === "grouping"){
                if(this.buttonName === 'Next'){
                    this.selectTab = "validations";
                    this.tabsContent.forEach((item)=>{
                        if(this.selectTab === item.id){
                            item.active = true;
                        }else{
                            item.active = false;
                        }
                    })
                    if(Object.keys(this.clickedDataPoint).length !== 0){
                        this.buttonName = "Save"
                    }else{
                        this.buttonName = "Add"
                    }
                }
            }else if(this.selectTab === "validations"){
                
                this.tabsContent.forEach((item)=>{
                    if(this.selectTab === item.id){
                        item.active = true;
                    }else{
                        item.active = false;
                    }
                })
            }
            this.checkedSaveNextButtonCondition();
        },
        groupDataPointsSorted(sortedDataPoints){
            this.groupDataPoints = sortedDataPoints
            if(this.isAddDatapointClicked){
             this.saveDataPoint.sortOrders=sortedDataPoints
            }else{
                this.clickedDataPoint.sortorders=sortedDataPoints
                this.saveDataPoint.sortOrders=sortedDataPoints
            }
        },
        clearSaveDataPointDetails(){
            this.saveDataPoint ={
                dataPointId: null,
                dataTypeId: 0,
                dataPointDependencyTypeId: 0,
                dataPointGroupId: 0,
                entityTypeId: 0,
                referencedEntityTypeId: null,
                dataPointName: "",
                dataPointDescription: "",
                dataPointExportName: "",
                arrayLength: 1,
                enumId: null,
                disabledAsDefault: false,
                showInFilter: true,
                showNACheckbox: false,
                definitions: [],
                validations: [],
                autoMappings: [],
                sortOrders: [],
            }
        },
        async searchDataPoints(value){
            this.showLoader=true
            this.isDatapointSearched=true
            let data ={
                searchKeyWord:value
            }
            await this.$store.dispatch(DATA_MODEL_ACTIONS.FETCH_DATAMODEL_DATAPOINTS_ON_SEARCH, { data })
            
            if(this.dataModelSearchDataPoints){
                for(let i=0;i<this.dataModelSearchDataPoints.result.length;i++){
                    for(let j=0;j<this.dataModelSearchDataPoints.result[i].datapoints.length;j++){
                        this.dataModelSearchDataPoints.result[i].datapoints[j]["entitytypeid"]=null
                        this.dataModelSearchDataPoints.result[i].datapoints[j]["showChildren"]=false
                        this.dataModelSearchDataPoints.result[i].datapoints[j]["itemLevel"]=0
                        this.dataModelSearchDataPoints.result[i].datapoints[j]["children"]=[]
                        this.dataModelSearchDataPoints.result[i].datapoints[j]["entityLevel"]=this.dataModelSearchDataPoints.result[i].entitytypedescription
                        this.dataModelSearchDataPoints.result[i].datapoints[j]["entityLevelTypeId"]=this.dataModelSearchDataPoints.result[i].entitytypeid
                        this.dataModelSearchDataPoints.result[i].datapoints[j]["isHover"]=false;
                    }
                    
                    this.addSearchDatapoints(this.dataModelSearchDataPoints.result[i].entitytypeid,this.dataModelSearchDataPoints.result[i].datapoints,this.treeViewItemsList[0])
                }
            }else{
                this.notificationMessage = "No result found";
                this.notificationType = "informational";
                this.notificationKey = "informational-example";
                this.showNotification = true;
            }
            
             this.showLoader=false
        },
        addSearchDatapoints(entitytypeid,datapoints,item){
            if(item.entitytypeid === entitytypeid){
                item.children[0].children = datapoints;
                item.children[0].showChildren=true
            }else{
                if(item.children.length > 1){
                    this.addSearchDatapoints(entitytypeid,datapoints,item.children[1])
                }
            }
            
        },
        emptySearchedDataPoints(){
            this.clearDataPoints(this.treeViewItemsList[0])
            this.isDatapointSearched=false
        },
        clearDataPoints(treeViewItem){
            treeViewItem.children[0].children = [];
            treeViewItem.children[0].showChildren=false
            if(treeViewItem.children.length > 1){
                    this.clearDataPoints(treeViewItem.children[1])
            }
        }
    }
}
</script>

