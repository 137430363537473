<template>
    <div id="attributes">
        <div class="mainSection">
            <div>
                <mds-layout-grid>
                    <mds-form action="javascript:void(0)">
                        <div class="attributeSection">
                            <mds-row>
                                <mds-col :cols="6" v-if="adminUser === true">
                                    <mds-input
                                        label="Name"
                                        maxlength="250"
                                        v-model.trim="datapointname"
                                        @input="onClickDataPointName"
                                        :disabled="datapointnamedisabled"
                                    ></mds-input>
                                </mds-col>
                                <mds-col :cols="6" v-if="adminUser === false">
                                    <span class="entityLevelTitle">Name</span>
                                    <div class="entityLevelName">
                                        {{ datapointname }}
                                    </div>
                                </mds-col>
                                <mds-col :cols="3">
                                    <span class="entityLevelTitle"
                                        >Entity Level</span
                                    >
                                    <div class="entityLevelName">
                                        {{ entityLevelName }}
                                    </div>
                                </mds-col>
                                <mds-col
                                    :cols="3"
                                    class="showNAField"
                                    v-if="adminUser === true"
                                >
                                    <mds-form>
                                        <mds-switch
                                            value="accept"
                                            @change="showNACheckbox"
                                            :disabled="showNADisabled"
                                            :checked="shownacheckbox"
                                            >Show 'NA' toggle</mds-switch
                                        >
                                    </mds-form>
                                </mds-col>
                            </mds-row>
                        </div>
                        <div class="attributeSection">
                            <mds-row>
                                <mds-col :cols="6" v-if="adminUser === true">
                                    <mds-select
                                        label="Type"
                                        v-model="datapointtype"
                                        :key="typeKey"
                                        @change="onDataPointType"
                                        placeholder="Select Type"
                                        :disabled="datapointtypedisabled"
                                    >
                                        <option
                                            v-for="(
                                                item, index
                                            ) in getDataTypes"
                                            :key="index"
                                            :value="item.label"
                                            :selected="
                                                selectedTypeId ===
                                                parseInt(item.label)
                                            "
                                        >
                                            {{ item.datatypedescription }}
                                        </option>
                                    </mds-select>
                                </mds-col>
                                <mds-col :cols="6" v-if="adminUser === false">
                                    <span class="entityLevelTitle">Type</span>
                                    <div class="entityLevelName">
                                        {{ dataTypeName }}
                                    </div>
                                </mds-col>
                            </mds-row>
                        </div>
                        <div
                            v-if="dataTypeRefEnum == true"
                            class="attributeSection"
                        >
                            <mds-row>
                                <mds-col :cols="6" v-if="adminUser === true">
                                    <mds-select
                                        v-if="datapointvalueref != ''"
                                        label="Value"
                                        v-model="datapointvalueref"
                                        @change="onDataPointValueRef"
                                        placeholder="Select Value"
                                        :disabled="datapointvaluedisabled"
                                    >
                                        <option
                                            v-for="(
                                                item, index
                                            ) in dataTypeValueDropDown"
                                            :key="index"
                                            :value="item.entitytypeid"
                                            :selected="
                                                selectedValueId ===
                                                parseInt(item.entitytypeid)
                                            "
                                        >
                                            {{ item.enitytypedecription }}
                                        </option>
                                    </mds-select>
                                    <mds-select
                                        v-if="datapointvalueenum != ''"
                                        label="Value"
                                        v-model="datapointvalueenum"
                                        @change="onDataPointValueEnum"
                                        placeholder="Select Value"
                                        :disabled="datapointvaluedisabled"
                                    >
                                        <option
                                            v-for="(
                                                item, index
                                            ) in dataTypeValueDropDown"
                                            :key="index"
                                            :value="item.enumid"
                                            :selected="
                                                selectedValueId ===
                                                parseInt(item.enumid)
                                            "
                                        >
                                            {{ item.enumname }}
                                        </option>
                                    </mds-select>
                                </mds-col>
                                <mds-col :cols="6" v-if="adminUser === false">
                                    <div v-if="datapointvalueref != ''">
                                        <span class="entityLevelTitle"
                                            >Value</span
                                        >
                                        <div class="entityLevelName">
                                            {{ selectedValueName }}
                                        </div>
                                    </div>
                                    <div v-if="datapointvalueenum != ''">
                                        <span class="entityLevelTitle"
                                            >Value</span
                                        >
                                        <div class="entityLevelName">
                                            {{ selectedValueName }}
                                        </div>
                                    </div>
                                </mds-col>
                                <mds-col :cols="6" v-if="adminUser === true">
                                    <mds-select
                                        label="Sub-Type"
                                        v-model="subTypeValue"
                                        @change="changeSubType"
                                        placeholder="Select Value"
                                        :disabled="subTypeDisabled"
                                    >
                                        <option
                                            v-for="(
                                                item, index
                                            ) in subTypeValueList"
                                            :key="index"
                                            :value="item.value"
                                            :selected="
                                                selectedSubTypeId ===
                                                parseInt(item.value)
                                            "
                                        >
                                            {{ item.text }}
                                        </option>
                                    </mds-select>
                                </mds-col>
                                <mds-col :cols="6" v-if="adminUser === false">
                                    <span class="entityLevelTitle"
                                        >Sub-Type</span
                                    >
                                    <div class="entityLevelName">
                                        {{ selectedSubTypeName }}
                                    </div>
                                </mds-col>
                            </mds-row>
                            <div class="attributeSection">
                                <mds-row>
                                    <mds-col :cols="12">
                                        <p class="dataTypeValues">
                                            {{ dataTypeValueTextarea }}
                                        </p>
                                    </mds-col>
                                </mds-row>
                            </div>
                        </div>
                        <div class="attributeSection">
                            <mds-row>
                                <mds-col :cols="12">
                                    <definition
                                        :languagelist="languagelist"
                                        :addDataPointButtonData="
                                            addDataPointButtonData
                                        "
                                        :definitions="definitions"
                                        :adminUser="adminUser"
                                        :saveDataPoint="saveDataPoint"
                                        :show="show"
                                        @multiLanguageButton="
                                            multiLanguageButton
                                        "
                                        @changeTextArea="changeTextArea"
                                        @deleteDefinition="deleteDefinition"
                                        @multiLanguageClickEvent="
                                            multiLanguageClickEvent
                                        "
                                        @downArrow="downArrow"
                                        @upArrow="upArrow"
                                    ></definition>
                                </mds-col>
                            </mds-row>
                        </div>
                    </mds-form>
                </mds-layout-grid>
            </div>
        </div>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import MdsSelect from "@mds/select";
import Definition from "./Definition.vue";
import MdsSwitch from "@mds/switch";
export default {
    name: "attributes",

    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsForm,
        MdsInput,
        MdsSelect,
        Definition,
        MdsSwitch,
    },

    props: [
        "selectedDataPoint",
        "selectedDataPointGroup",
        "dataTypeList",
        "languagelist",
        "addDataPointButtonData",
        "dataTypeRefEnum",
        "dataTypeValueDropDown",
        "dataTypeValueTextarea",
        "definitions",
        "adminUser",
        "entityLevelName",
        "saveDataPoint",
    ],

    data() {
        return {
            datapointname: "",
            datapointtype: "",
            datapointvalueref: "",
            datapointvalueenum: "",
            getDataTypes: [],
            selectedTypeId: 0,
            selectedValueId: 0,
            selectedSubTypeId: 1,
            selectedSubTypeName: "",
            datapointnamedisabled: false,
            datapointtypedisabled: false,
            datapointvaluedisabled: false,
            showNADisabled: false,
            shownacheckbox: false,
            subTypeValue: 1,
            subTypeDisabled: false,
            subTypeValueList: [
                {
                    value: 0,
                    text: "Multiple Value",
                },
                {
                    value: 1,
                    text: "Singal Value",
                },
            ],
            show: false,
            dataTypeName: "",
            typeKey:0,
        };
    },
    watch: {
        selectedDataPoint(value) {
            this.show = false;
            this.datapointname = value.datapointname;
            this.datapointtype = value.datatypelabel;
            this.selectedTypeId = value.datatypelabel;
            this.getDataTypes.forEach((item) => {
                if (value.datatypelabel === item.label) {
                    this.dataTypeName = item.datatypedescription;
                }
            });
            this.shownacheckbox = value.shownacheckbox;
            if (value.enumid != null) {
                this.datapointvalueenum = value.enumid;
                this.selectedValueId = value.enumid;
                this.selectedValueName = value.enumname;
                this.datapointvalueref = "";
            }
            if (value.referencedentitytypeid != null) {
                this.datapointvalueref = value.referencedentitytypeid;
                this.selectedValueId = value.referencedentitytypeid;
                this.selectedValueName = value.referencedentitytypename;
                this.datapointvalueenum = "";
            }
            this.subTypeValue = value.arraylength;
            this.selectedSubTypeId = value.arraylength;
            if (this.selectedSubTypeId === 1) {
                this.selectedSubTypeName = "Singal Value";
            }
            if (this.selectedSubTypeId === 0) {
                this.selectedSubTypeName = "Multiple Value";
            }
            this.datapointnamedisabled = true;
            this.datapointtypedisabled = true;
            this.datapointvaluedisabled = true;
            this.showNADisabled = true;
            this.subTypeDisabled = true;
            if (this.adminUser === true && value.datapointisinuse === false) {
                this.datapointnamedisabled = false;
                this.datapointtypedisabled = false;
                this.datapointvaluedisabled = false;
                this.showNADisabled = false;
                this.subTypeDisabled = false;
            }
        },
        selectedDataPointGroup(value) {
            console.log(value);
        },
        dataTypeList(value) {
            this.getDataTypes = value;
        },
        addDataPointButtonData(value) {
            if (value) {
                this.datapointnamedisabled = false;
                this.datapointtypedisabled = false;
                this.datapointvaluedisabled = false;
                this.showNADisabled = false;
                this.subTypeDisabled = false;
                this.subTypeValue = 1;
                this.shownacheckbox = false;
                this.datapointvalueenum = "";
                this.datapointvalueref = "";
                this.selectedTypeId = 0;
                this.selectedValueId = 0;
                this.selectedValueName = "";
                this.show = false;
            }
        },
    },
    mounted() {
        this.getDataTypes = this.dataTypeList;
        this.datapointname = this.saveDataPoint.dataPointName;
        this.shownacheckbox = this.saveDataPoint.showNACheckbox;
        this.datapointtype = this.saveDataPoint.dataTypeLabel;
        this.selectedTypeId = this.saveDataPoint.dataTypeLabel;
        this.subTypeValue = this.saveDataPoint.arrayLength;
        this.selectedSubTypeId = this.saveDataPoint.arrayLength;
        if(this.saveDataPoint.dataTypeLabel === "Enum"){
            if (this.saveDataPoint.enumId !== null) {
                this.datapointvalueenum = this.saveDataPoint.enumId;
                this.selectedValueId = this.saveDataPoint.enumId;
                this.datapointvalueref = "";
            }
            else{
                this.datapointvalueenum = undefined;
                this.datapointvalueref = "";
            }
        }
        if(this.saveDataPoint.dataTypeLabel === "Reference"){
            if (this.saveDataPoint.referencedEntityTypeId !== null) {
                this.datapointvalueref = this.saveDataPoint.referencedEntityTypeId;
                this.selectedValueId = this.saveDataPoint.referencedEntityTypeId;
                this.datapointvalueenum = "";
            }else{
                this.datapointvalueenum = "";
                this.datapointvalueref = undefined;
            }
        }

        if (Object.keys(this.selectedDataPoint).length !== 0) {
            this.show = false;
            this.datapointname = this.selectedDataPoint.datapointname;
            this.datapointtype = this.selectedDataPoint.datatypelabel;
            this.selectedTypeId = this.selectedDataPoint.datatypelabel;
            this.shownacheckbox = this.selectedDataPoint.shownacheckbox;

            this.getDataTypes.forEach((item) => {
                if (this.selectedDataPoint.datatypelabel === item.label) {
                    this.dataTypeName = item.datatypedescription;
                }
            });
            
            if (this.selectedDataPoint.enumid != null) {
                this.datapointvalueenum = this.selectedDataPoint.enumid;
                this.selectedValueId = this.selectedDataPoint.enumid;
                this.selectedValueName = this.selectedDataPoint.enumname;
                this.datapointvalueref = "";
            }
            if (this.selectedDataPoint.referencedentitytypeid != null) {
                this.datapointvalueref =
                    this.selectedDataPoint.referencedentitytypeid;
                this.selectedValueId =
                    this.selectedDataPoint.referencedentitytypeid;
                this.selectedValueName =
                    this.selectedDataPoint.referencedentitytypename;
                this.datapointvalueenum = "";
            }
            this.subTypeValue = this.selectedDataPoint.arraylength;
            this.selectedSubTypeId = this.selectedDataPoint.arraylength;
            if (this.selectedSubTypeId === 1) {
                this.selectedSubTypeName = "Singal Value";
            }
            if (this.selectedSubTypeId === 0) {
                this.selectedSubTypeName = "Multiple Value";
            }
            this.datapointnamedisabled = true;
            this.datapointtypedisabled = true;
            this.datapointvaluedisabled = true;
            this.showNADisabled = true;
            this.subTypeDisabled = true;
            if (
                this.adminUser === true &&
                this.selectedDataPoint.datapointisinuse === false
            ) {
                this.datapointnamedisabled = false;
                this.datapointtypedisabled = false;
                this.datapointvaluedisabled = false;
                this.showNADisabled = false;
                this.subTypeDisabled = false;
            }
        }
        this.typeKey+=1;
    },
    methods: {
        onClickDataPointName(event) {
            this.$emit("onClickDataPointName", event);
        },
        changeSubType(event) {
            this.selectedSubTypeId = event;
            this.$emit("changeSubType", event);
        },
        showNACheckbox(event) {
            this.$emit("showNACheckbox", event);
        },
        deleteDefinition(value, index) {
            this.$emit("deleteDefinition", value, index);
        },
        onDataPointValueEnum(event) {
            this.$emit("onDataPointValueEnum", event);
        },
        onDataPointValueRef(event) {
            this.$emit("onDataPointValueRef", event);
        },
        onDataPointType(value) {
            let selectedVal;
            this.subTypeValue = 1;
            this.getDataTypes.forEach((item) => {
                if (value == item.label) {
                    selectedVal = item;
                }
            });
            if (selectedVal.label == "Enum") {
                this.datapointvalueenum = undefined;
                this.datapointvalueref = "";
            }
            if (selectedVal.label == "Reference") {
                this.datapointvalueenum = "";
                this.datapointvalueref = undefined;
            }
            this.$emit("onDataPointType", selectedVal);
        },
        multiLanguageClickEvent(value) {
            this.show = false;
            this.$emit("multiLanguageClickEvent", value);
        },
        upArrow(value) {
            this.$emit("upArrow", value);
        },
        downArrow(value) {
            this.$emit("downArrow", value);
        },
        changeTextArea(event, value) {
            this.$emit("changeTextArea", event, value);
        },
        multiLanguageButton() {
            this.show = !this.show;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";

.mainSection {
    margin-top: 16px;
}
.attributeSection {
    margin-top: 20px;
}
.readOnlyText {
    box-shadow: none;
    background-color: white;
}
.entityLevelTitle {
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
}
.entityLevelName {
    margin-top: 10px;
}
.showNAField {
    margin-top: 33px;
}
.dataTypeValues {
    height: 85px;
    overflow-y: auto;
}
</style>
